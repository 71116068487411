import { AsyncData, LoadingState } from '@activia/ngx-components';
import { createReducer, on, Action } from '@ngrx/store';
import { IExperienceTemplate } from '../../models/experience-template.interface';

import * as ExperienceTemplateActions from './experience-template.actions';

export const EXPERIENCE_TEMPLATE_FEATURE_KEY = 'experience-template';

export interface IExperienceTemplateState {
  /** All experience templates from the database */
  experienceTemplates: AsyncData<Record<string, IExperienceTemplate>>;

  temporaryDeletedExperienceTemplate: IExperienceTemplate[];
}

export const initialExperienceTemplateState: IExperienceTemplateState = {
  experienceTemplates: {
    data: {},
    state: LoadingState.INIT,
  },

  temporaryDeletedExperienceTemplate: undefined,
};

const reducer = createReducer<IExperienceTemplateState>(
  initialExperienceTemplateState,

  //#region Fetch Experience Templates
  on(ExperienceTemplateActions.FetchExperienceTemplates, (state) => ({
    ...state,
    experienceTemplates: {
      ...state.experienceTemplates,
      state: LoadingState.LOADING,
    },
  })),

  on(ExperienceTemplateActions.FetchExperienceTemplatesSuccess, (state, action) => {
    // Transform list into map
    const newExperienceTemplates = action.experienceTemplates.reduce((acc, current) => {
      acc[current.label] = current;
      return acc;
    }, {});

    return {
      ...state,
      experienceTemplates: {
        data: newExperienceTemplates,
        state: LoadingState.LOADED,
      },
    };
  }),

  on(ExperienceTemplateActions.FetchExperienceTemplatesFail, (state, action) => ({
    ...state,
    experienceTemplates: {
      ...state.experienceTemplates,
      state: { errorMsg: action.errorMessage },
    },
  })),
  //#endregion

  //#region Create Experience Template

  on(ExperienceTemplateActions.CreateExperienceTemplateSuccess, (state, action) => ({
    ...state,
    experienceTemplates: {
      ...state.experienceTemplates,
      data: {
        ...state.experienceTemplates.data,
        [action.experienceTemplate.label]: { ...action.experienceTemplate }, // Add the new experience template
      },
    },
  })),
  //#endregion

  //#region Delete Experience template
  on(ExperienceTemplateActions.DeleteExperienceTemplate, (state, action) => {
    // Remove experience template from the map
    const { [action.experienceTemplateId]: removedExperienceTemplates, ...experienceTemplates } = state.experienceTemplates.data;

    return {
      ...state,
      experienceTemplates: {
        ...state.experienceTemplates,
        data: experienceTemplates,
      },
      temporaryDeletedExperienceTemplate: [...state.temporaryDeletedExperienceTemplate, removedExperienceTemplates],
    };
  }),

  on(ExperienceTemplateActions.DeleteExperienceTemplateSuccess, (state, action) => ({
    ...state,
    temporaryDeletedExperienceTemplate: state.temporaryDeletedExperienceTemplate.filter((e) => e.label !== action.experienceTemplateId),
  })),

  on(ExperienceTemplateActions.DeleteExperienceTemplateFail, (state, action) => {
    const deletedExperienceTemplate = state.temporaryDeletedExperienceTemplate.find((e) => e.label === action.experienceTemplateId);

    return {
      ...state,
      experienceTemplates: {
        ...state.experienceTemplates,
        data: {
          ...state.experienceTemplates.data,
          [action.experienceTemplateId]: deletedExperienceTemplate, // Re-add the experience template that failed to delete
        },
      },
      temporaryDeletedExperienceTemplate: state.temporaryDeletedExperienceTemplate.filter((e) => e.label !== action.experienceTemplateId),
    };
  }),
  //#endregion

  //#region Edit Experience Template
  on(ExperienceTemplateActions.EditExperienceTemplateSuccess, (state, action) => ({
    ...state,
    experienceTemplates: {
      ...state.experienceTemplates,
      data: {
        ...state.experienceTemplates.data,
        [action.experienceTemplate.label]: {
          ...action.experienceTemplate,
        },
      },
    },
  }))
  //#endregion
);

export const experienceTemplateReducer = (state: IExperienceTemplateState | undefined, action: Action): IExperienceTemplateState => reducer(state, action);
