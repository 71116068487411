import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import * as BoardActions from './board.actions';
import * as SiteActions from '../site-management.actions';
import * as DisplayActions from '../display/display.actions';
import { AsyncDataState, LoadingState } from '@activia/ngx-components';
import { IBoard } from '../../models/board-config.interface';
import { moveItemInArray } from '@angular/cdk/drag-drop';

// Sort based on order first, and then if equal based on name
const sortComparer = (a: IBoard, b: IBoard) => (a.order - b.order !== 0 ? a.order - b.order : a.name.localeCompare(b.name));

export const BOARD_FEATURE_KEY = 'board';

export const adapter: EntityAdapter<IBoard> = createEntityAdapter<IBoard>({
  sortComparer,
});

export interface BoardState extends EntityState<IBoard> {
  currentSiteId: number;
  status: AsyncDataState;
  lastAttempt: Date;
  lastSuccess: Date;

  temporaryDeletedBoards: Record<number, IBoard>; // Optimistic delete
}

export const initialState: BoardState = adapter.getInitialState({
  currentSiteId: undefined,
  status: LoadingState.INIT,
  lastAttempt: undefined,
  lastSuccess: undefined,
  temporaryDeletedBoards: {},
});

export const reducer = createReducer(
  initialState,

  //#region API Changes
  //#region FetchSiteDetail
  on(SiteActions.FetchSiteDetail, (state, action) => ({
    ...adapter.removeAll(state),
    currentSiteId: action.siteId,
    status: LoadingState.LOADING,
    lastAttempt: new Date(),
  })),

  on(SiteActions.FetchSiteDetailSuccess, (state, action) => ({
    ...adapter.setAll(action.boards, state),
    status: LoadingState.LOADED,
    lastSuccess: new Date(),
  })),

  on(SiteActions.FetchSiteDetailFail, (state, action) => ({
    ...state,
    status: { errorMsg: action.error },
  })),
  //#endregion

  //#region CreateTemplateBoards
  on(SiteActions.CreateTemplateBoards, (state, action) => {
    // Check if the updated site is the current site
    if (action.site.id === state.currentSiteId) {
      return {
        ...state,
        status: LoadingState.LOADING,
        lastAttempt: new Date(),
      };
    } else {
      // Do nothing if we're not editing the current site
      return state;
    }
  }),

  on(SiteActions.CreateTemplateBoardsSuccess, (state, action) => {
    // Check if the updated site is the current site
    if (action.site.id === state.currentSiteId) {
      const addedBoards = action.boards.map((board) => ({ ...board, order: board.order + state.ids.length })); // Sanitize board order

      return {
        ...adapter.addMany(addedBoards, state),
        status: LoadingState.LOADED,
        lastSuccess: new Date(),
      };
    } else {
      // Do nothing if we're not editing the current site
      return state;
    }
  }),

  on(SiteActions.CreateTemplateBoardsFail, (state, action) => {
    // Check if the updated site is the current site
    if (action.siteId === state.currentSiteId) {
      return {
        ...state,
        status: { errorMsg: action.error },
      };
    } else {
      // Do nothing if we're not editing the current site
      return state;
    }
  }),
  //#endregion

  //#region AddBoard
  on(BoardActions.AddBoard, (state) => ({
    ...state,
    status: LoadingState.LOADING,
  })),

  on(BoardActions.AddBoardSuccess, (state, action) => ({
    ...adapter.addOne(action.board, state),
    status: LoadingState.LOADED,
  })),

  on(BoardActions.AddBoardFail, (state, action) => ({
    ...state,
    status: { errorMsg: action.error },
  })),
  //#endregion

  //#region DeleteBoard
  on(BoardActions.DeleteBoard, (state, action) => ({
    ...adapter.removeOne(action.boardId, state), // Optimistic delete
    temporaryDeletedBoards: {
      ...state.temporaryDeletedBoards,
      [action.boardId]: { ...state.entities[action.boardId] }, // Temporary deleted board saved in case it fails
    },
  })),

  on(BoardActions.DeleteBoardSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [action.boardId]: deletedBoard, ...temporaryDeletedBoards } = state.temporaryDeletedBoards; // Delete succeed so remove deleted board from backup cache

    return {
      ...state,
      temporaryDeletedBoards,
    };
  }),

  on(BoardActions.DeleteBoardFail, (state, action) => {
    // Delete failed so remove deleted board from backup cache and add it back to entities
    const { [action.boardId]: failedDeletedBoard, ...temporaryDeletedBoards } = state.temporaryDeletedBoards;

    return {
      ...adapter.addOne(failedDeletedBoard, state),
      temporaryDeletedBoards,
      status: { errorMsg: action.error },
    };
  }),
  //#endregion

  //#region UpdateBoard
  on(BoardActions.UpdateBoard, (state) => ({
    ...state,
    status: LoadingState.LOADING,
  })),

  on(BoardActions.UpdateBoardSuccess, (state, action) => ({
    ...adapter.addOne(action.board, adapter.removeOne(action.boardId, state)),
    status: LoadingState.LOADED,
  })),

  on(BoardActions.UpdateBoardFail, (state, action) => ({
    ...state,
    status: { errorMsg: action.error },
  })),
  //#endregion

  //#region UpdateBoardSize
  on(BoardActions.UpdateBoardSize, (state) => ({
    ...state,
    status: LoadingState.LOADING,
  })),

  on(BoardActions.UpdateBoardSizeSuccess, (state, action) => ({
    ...adapter.updateOne(action.board, state),
    status: LoadingState.LOADED,
  })),
  //#endregion

  //#region SwapBoardOrder
  on(BoardActions.SwapBoardOrder, (state, action) => {
    // Optimistic swap
    const getOrgPath = (board: IBoard) => board.organizationPath.split('.').slice(0, -1).join('.'); // Remove board name from org path

    // Get board from same orgPath
    const orgPath = getOrgPath(state.entities[action.boardId]);
    const boards = state.ids.map((id) => state.entities[id]).filter((board) => getOrgPath(board) === orgPath);

    moveItemInArray(boards, action.fromIndex, action.toIndex);

    // Change order attribute in boards and transform to dictionnary
    const boardEntities = Object.assign(
      {},
      ...boards.map((board, index) => ({
        [board.id]: {
          ...board,
          order: index,
        },
      }))
    ) as Dictionary<IBoard>;
    const entities = { ...state.entities, ...boardEntities };
    const ids = Object.values(entities)
      .sort(sortComparer)
      .map((board) => board.id);

    return {
      ...state,
      ids,
      entities,
      status: LoadingState.LOADING,
    };
  }),

  on(BoardActions.SwapBoardOrderSuccess, (state) => ({
    ...state,
    status: LoadingState.LOADED,
  })),

  on(BoardActions.SwapBoardOrderFail, (state, action) => {
    // Swap failed, swap boards back to previous position
    const getOrgPath = (board: IBoard) => board.organizationPath.split('.').slice(0, -1).join('.'); // Remove board name from org path

    // Get board from same orgPath
    const orgPath = getOrgPath(state.entities[action.boardId]);
    const boards = state.ids.map((id) => state.entities[id]).filter((board) => getOrgPath(board) === orgPath);

    moveItemInArray(boards, action.toIndex, action.fromIndex);

    // Change order attribute in boards and transform to dictionnary
    const boardEntities = Object.assign(
      {},
      ...boards.map((board, index) => ({
        [board.id]: {
          ...board,
          order: index,
        },
      }))
    ) as Dictionary<IBoard>;
    const entities = { ...state.entities, ...boardEntities };
    const ids = Object.values(entities)
      .sort(sortComparer)
      .map((board) => board.id);

    return {
      ...state,
      ids,
      entities,
      status: { errorMsg: action.error },
    };
  }),
  //#endregion
  //#endregion

  //#region UI changes
  on(BoardActions.ToggleLockBoard, (state, action) =>
    adapter.updateOne(
      {
        id: action.boardId,
        changes: { isLocked: !state.entities[action.boardId].isLocked },
      },
      state
    )
  ),
  on(BoardActions.ToggleAccordionBoard, (state, action) =>
    adapter.updateOne(
      {
        id: action.boardId,
        changes: {
          isAccordionOpen: !state.entities[action.boardId].isAccordionOpen, // Toggle accordion
        },
      },
      state
    )
  ),
  //#endregion

  //#region Display changes
  on(DisplayActions.AddDisplaySuccess, (state, action) => ({
    ...adapter.updateOne(
      {
        id: action.boardId,
        changes: { displays: action.displays.map((e) => e?.id) },
      },
      state
    ),
  })),

  on(DisplayActions.DeleteDisplaySuccess, (state, action) => ({
    ...adapter.updateOne(
      {
        id: action.boardId,
        changes: { displays: action.displays.map((e) => e?.id) },
      },
      state
    ),
  }))
  //#endregion
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
