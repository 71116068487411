import { createReducer, on } from '@ngrx/store';
import * as SiteOrgPathDefinitionActions from './site-org-path-definition.actions';
import { IOrgPathDefNode, IOrgPathDefRoot } from '../../model/board-org-path-definition.interface';
import { AsyncData, AsyncDataState, LoadingState } from '@activia/ngx-components';
import { IJsonSchema } from '@activia/json-schema-forms';

export const siteOrgPathDefinitionsFeatureKey = 'siteOrgPathDefinitions';

export interface SiteOrgPathDefState {
  orgPathDefinition: AsyncData<IOrgPathDefRoot>;
  tagKeys: Record<string, IJsonSchema>;
  errorFetchOrgPath: any;
  orgPathSaveState: AsyncDataState; // Used to know if orgpath has been saved successfully
}

export const siteOrgPathInitialState: SiteOrgPathDefState = {
  orgPathDefinition: {
    data: undefined,
    state: LoadingState.INIT,
  },
  errorFetchOrgPath: undefined,
  tagKeys: {},
  orgPathSaveState: LoadingState.INIT,
};

export const siteOrgPathReducer = createReducer(
  siteOrgPathInitialState,

  //#region Fetch
  on(SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinition, (state) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
      data: null,
    },
    tagKeys: {},
  })),

  on(SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinitionSuccess, (state, action) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
      data: action.siteOrgPathDefinition,
    },
    tagKeys: action.tagKeys,
  })),

  on(SiteOrgPathDefinitionActions.FetchSiteOrgPathDefinitionFail, (state, action) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADED,
      data: { type: 'site', root: {} as IOrgPathDefNode }, // If error we fallback to an empty orgpath Definition
    },
    errorFetchOrgPath: action.error,
    tagKeys: {},
  })),
  //#endregion

  //#region Save

  on(SiteOrgPathDefinitionActions.SaveSiteOrgPathDefinition, (state) => ({
    ...state,
    orgPathSaveState: LoadingState.LOADING,
  })),

  on(SiteOrgPathDefinitionActions.SaveSiteOrgPathDefinitionSuccess, (state, action) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
      data: action.siteOrgPathDefinition,
    },
    tagKeys: action.tagKeys,
    orgPathSaveState: LoadingState.LOADED,
  })),

  on(SiteOrgPathDefinitionActions.SaveSiteOrgPathDefinitionFail, (state, action) => ({
    ...state,
    orgPathSaveState: action.error,
  }))
  //#endregion
);
