import { AlarmEvent, AlarmEventLevel, AlarmEvents } from '@amp/devices';

/** Possible alarm event used in representing the status of an enclosure in a site */
export type SiteMonitoringKeyMetricEnclosureStatus =
  AlarmEvent.DoorOpened | AlarmEvent.FanError | AlarmEvent.FilterService | AlarmEvent.LatchBypass | AlarmEvent.ThermalTrip;

/** All alarm events used to represent the status of an enclosure in a site */
export const SiteMonitoringKeyMetricEnclosureStatuses: SiteMonitoringKeyMetricEnclosureStatus[] =
  [AlarmEvent.DoorOpened, AlarmEvent.FanError, AlarmEvent.FilterService, AlarmEvent.LatchBypass, AlarmEvent.ThermalTrip];

export const getSiteMonitoringKeyMetricEnclosureAlarmEvents = () =>
  AlarmEvents.filter((alarmEvent) => SiteMonitoringKeyMetricEnclosureStatuses.includes(alarmEvent.id as any));

/**
 * Status of each type of alarm event in enclosure status.
 * count: count of each type of alarm event of all enclosures in a site
 * level: highest level of alarm within this alarm event among all enclosures in a site
 * E.g.
 * {
 *   AlarmEvent.DoorOpen: { count: 2, level: AlarmEventLevel.Error },
 *   AlarmEvent.FilterService: { count: 4, level: AlarmEventLevel.Critical },
 *   AlarmEvent.LatchBypass: { count: 1, level: AlarmEventLevel.Info },
 * }
 */
export type KeyMetricEnclosureStatusCount = Record<SiteMonitoringKeyMetricEnclosureStatus, { count: number; level: AlarmEventLevel }>;

/**
 * Enclosure status of a site.
 * E.g.
 * {
 *   count: {
 *     AlarmEvent.DoorOpen: { count: 2, level: AlarmEventLevel.Error },
 *     AlarmEvent.FilterService: { count: 4, level: AlarmEventLevel.Critical },
 *     AlarmEvent.LatchBypass: { count: 1, level: AlarmEventLevel.Info },
 *   },
 *   level: AlarmEventLevel.Critical
 * }
 */
export interface IKeyMetricSiteEnclosureStatus {
  // Counts of each type of alarm event among all enclosures in a site
  count: KeyMetricEnclosureStatusCount;

  // Highest level of alarm among all alarms in a site
  level: AlarmEventLevel;
}

/**
 * Contains the configuration for the enclosure status key metric
 */
export interface ISiteMonitoringKeyMetricEnclosureStatusSettings {
  enclosureStatus: SiteMonitoringKeyMetricEnclosureStatus[];
}
