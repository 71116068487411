import { IColumnDefinition } from '@amp/column-picker';
import { AlarmEvent, AlarmEventNamespace, HealthStatusCode } from '@amp/devices';
import { SiteMonitoredValue } from './site-monitored-value.enum';
import { SiteMonitoringKeyMetric } from './site-monitoring-key-metric.enum';
import { ISiteMonitoringKeyMetricOptimisticSettings } from './key-metrics/site-monitoring-key-metric-optimistic-settings.interface';
import { ISiteMonitoringKeyMetricServiceSettings } from './key-metrics/site-monitoring-key-metric-service-settings.interface';
import {
  ISiteMonitoringKeyMetricEnclosureStatusSettings,
  SiteMonitoringKeyMetricEnclosureStatuses
} from './key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import { ISiteMonitoringKeyMetricHideOkSettings } from './key-metrics/site-monitoring-key-metric-hide-ok-settings.interface';
import { ISiteMonitoringKeyMetricShowTooltipSettings } from './key-metrics/site-monitoring-key-metric-show-tooltip-settings.interface';

/**  @ignore */
const getKeyMetricInfo = (id: SiteMonitoringKeyMetric) => ({
    id,
    name: `SITE_MONITORING.KEY_METRICS.${id}.TITLE_35`,
    description: `SITE_MONITORING.KEY_METRICS.${id}.DESCRIPTION_200`,
  });

/**
 * This contains metadata about all the site monitoring key metrics
 * - id
 * - i18n keys for name + description
 * - display types and default display types (when applicable)
 * - the monitored values to fetch for each key metric
 * - any extra specific settings for each key metric (e.g. optimisticViewEnabled, etc..)
 */
export const SITE_MONITORING_KEY_METRICS: IColumnDefinition<IKeyMetricExtra>[] = [
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.HealthStatusSummary),
    extra: {
      monitoringValues: [SiteMonitoredValue.HealthStatus, SiteMonitoredValue.LastUpdate],
      defaultSettings: <ISiteMonitoringKeyMetricOptimisticSettings>{
        optimisticViewEnabled: false,
      },
    },
    sample: { [`${HealthStatusCode.OK}`]: 10, [`${HealthStatusCode.WARNING}`]: 5, [`${HealthStatusCode.ERROR}`]: 52, [`${HealthStatusCode.UNREACHABLE}`]: 2, [`${HealthStatusCode.NOT_MONITORED}`]: 4 },
  },
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.ContentStatus),
    extra: {
      monitoringValues: [SiteMonitoredValue.ContentStatus, SiteMonitoredValue.LastContentUpToDate, SiteMonitoredValue.LastContentUpdate, SiteMonitoredValue.LastContentFailedUpdate],
      defaultSettings: <ISiteMonitoringKeyMetricOptimisticSettings>{
        optimisticViewEnabled: false,
      },
    },
  },
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.Services),
    extra: {
      monitoringValues: [SiteMonitoredValue.HttpService, SiteMonitoredValue.ServicePlayer, SiteMonitoredValue.OmnicastStatus],
      defaultSettings: <ISiteMonitoringKeyMetricServiceSettings>{
        services: [SiteMonitoredValue.ServicePlayer, SiteMonitoredValue.HttpService],
      },
    },
  },
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.CodState),
    extra: {
      monitoringValues: [SiteMonitoredValue.CodState, SiteMonitoredValue.CodLastUpdate],
    },
  },
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.PosState),
    extra: {
      monitoringValues: [SiteMonitoredValue.PosState, SiteMonitoredValue.PosLastUpdate],
    },
  },
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.SystemDisk),
    extra: {
      monitoringValues: [SiteMonitoredValue.SystemDisk],
    },
  },
  {
    ...getKeyMetricInfo(SiteMonitoringKeyMetric.EnclosureStatus),
    extra: {
      alarmTypes: [
        { namespace: AlarmEventNamespace.Enclosure, name: AlarmEvent.FanError },
        { namespace: AlarmEventNamespace.Enclosure, name: AlarmEvent.FilterService },
        { namespace: AlarmEventNamespace.Enclosure, name: AlarmEvent.LatchBypass },
        { namespace: AlarmEventNamespace.Enclosure, name: AlarmEvent.DoorOpened },
        { namespace: AlarmEventNamespace.Enclosure, name: AlarmEvent.ThermalTrip },
      ],
      defaultSettings: <ISiteMonitoringKeyMetricOptimisticSettings & ISiteMonitoringKeyMetricHideOkSettings & ISiteMonitoringKeyMetricShowTooltipSettings & ISiteMonitoringKeyMetricEnclosureStatusSettings>{
        optimisticViewEnabled: false,
        hideKeyMetricOnOk: false,
        showTooltip: false,
        enclosureStatus: SiteMonitoringKeyMetricEnclosureStatuses,
      },
    },
  },
];

/** Extra information for each key metric **/
export interface IKeyMetricExtra {
  /** The monitoring values that are needed for the display of the key metric **/
  monitoringValues?: SiteMonitoredValue[];

  /** Data for enclosure status key metric comes from alarms instead of from monitoring values */
  alarmTypes?: IKeyMetricAlarmType[];

  /** The default settings for the key metric **/
  defaultSettings?: unknown;
}

export interface IKeyMetricAlarmType {
  namespace: AlarmEventNamespace;
  name: AlarmEvent;
}
