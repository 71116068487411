import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {ITaskPanelItemComponent, ModalService, ModalType} from '@activia/ngx-components';
import {
  BehaviorSubject,
  concatMap,
  map,
  Observable,
  of,
  pairwise,
  ReplaySubject,
  Subject,
  takeUntil,
} from 'rxjs';
import {DeviceListService} from '../../services/device-list.service';
import {differenceBy} from 'lodash/array';
import {catchError, startWith, tap} from 'rxjs/operators';
import {DevicesFacade} from '../../store/devices.facade';
import {
  ExportDevicesTaskIcons,
  ExportDevicesTaskStatus,
  ExportDevicesTaskThemes,
  IExportDevicesTask
} from '../../model/export-devices-task.interface';
import {Store} from '@ngrx/store';
import {
  DevicesAddExportTaskFail,
  DevicesAddExportTaskSuccess,
  DevicesClearExportTasks
} from '../../store/devices.actions';
import {ExportDevicesTasksSummaryModalComponent} from '../export-devices-tasks-summary-modal/export-devices-tasks-summary-modal.component';
import {Overlay} from '@angular/cdk/overlay';

@Component({
  selector: 'amp-export-devices-task-panel-item',
  templateUrl: './export-devices-task-panel-item.component.html',
  styleUrls: ['./export-devices-task-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportDevicesTaskPanelItemComponent implements ITaskPanelItemComponent, OnDestroy {
  /** Emits when all tasks are completed */
  completed$ = new BehaviorSubject(false);

  MAX_CONCURRENT_TASKS = 50;

  ExportDevicesTaskIcons = ExportDevicesTaskIcons;
  ExportDevicesTaskThemes = ExportDevicesTaskThemes;

  private _taskSubject = new ReplaySubject<IExportDevicesTask>();

  private _modelSubject = new BehaviorSubject({
    errorCount: 0,
    successCount: 0,
    totalCount: 0,
    completePercentage: 0,
    taskCompleted: true,
  });

  get model$(): Observable<{
    errorCount: number;
    successCount: number;
    totalCount: number;
    completePercentage: number;
    taskCompleted: boolean;
  }> {
    return this._modelSubject.asObservable();
  }

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _devicesFacade: DevicesFacade,
    private _deviceListService: DeviceListService,
    private _store: Store,
    private _modalService: ModalService,
    private _overlay: Overlay,
  ) {
    this._devicesFacade.exportTasks$.pipe(
      startWith([]),
      pairwise(),
      map(([prev, curr]) => {
        const newTasks = differenceBy(curr, prev, 'id');
        newTasks.forEach((task) => {
          const totalCount = this._modelSubject.value.totalCount + 1;
          const model = {
            ...this._modelSubject.value,
            totalCount,
            completePercentage: ((this._modelSubject.value.successCount + this._modelSubject.value.errorCount) / totalCount) * 100,
            taskCompleted: false
          };
          this._modelSubject.next(model);
          this._taskSubject.next(task);
        });
      }),
      takeUntil(this._componentDestroyed$)
    ).subscribe();

    this._taskSubject.asObservable().pipe(
      concatMap((task) => this._deviceListService.getExportableDevicesCsvData(task.list, task.withManagerId, task.devices).pipe(
        map((csvData) => {
          if (!!csvData) {
            this._store.dispatch(DevicesAddExportTaskSuccess({ task: { ...task, status: 'SUCCESS' as ExportDevicesTaskStatus, csvData } }));
            return { success: true };
          } else {
            this._store.dispatch(DevicesAddExportTaskFail({ task: { ...task, status: 'FAIL' as ExportDevicesTaskStatus } }));
            return { success: false };
          }
        }),
        catchError((_) => of({ success: false })),
      )),
      tap(({ success }) => {
        const model = { ...this._modelSubject.value };
        if (success) {
          model.successCount++;
        } else {
          model.errorCount++;
        }
        model.completePercentage = ((model.successCount + model.errorCount) / model.totalCount) * 100;
        model.taskCompleted = model.completePercentage === 100;
        this._modelSubject.next(model);
        this.completed$.next(model.taskCompleted);
      }),
      takeUntil(this._componentDestroyed$)
    ).subscribe();
  }

  /** @ignore **/
  ngOnDestroy(): void {
    this._store.dispatch(DevicesClearExportTasks());
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  showSummary() {
    this._modalService.open<ExportDevicesTasksSummaryModalComponent, void>(
      ExportDevicesTasksSummaryModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        height: '50%',
        width: '800px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('100px'),
      },
      ModalType.Dialog
    );
  }
}
