<ng-container [formGroup]="form">
  <avn-json-schema-control-field
    *ngIf="schema"
    [jsonSchema]="schema"
    [formControlName]="propertyName"
    [required]="true"
    [errorsText]="propertyName | applyFunction : getNameCustomError : [] : this"
    (changed)="formChanged.emit()"
  >
  </avn-json-schema-control-field>

  <ng-container *ngIf="nodeDefinition.childOneOf as dependencies">
    <ng-container *ngFor="let childSchema of dependencies">
      <ng-container *ngIf="!childSchema.dependentItem || childSchema.dependentItem === form.controls[propertyName]?.value">
        <amp-orgpath-form-group
          [controlAsyncValidatorMap]="controlAsyncValidatorMap"
          [nodeDefinition]="childSchema"
          [tagDefinition]="tagDefinition"
          [editable]="editable"
          (formChanged)="formChanged.emit()"
        ></amp-orgpath-form-group>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
