import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { dataOnceReady, ModalService, ModalType } from '@activia/ngx-components';
import { siteManagementEntities } from '../store/site-management.selectors';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ISiteManagementState } from '../store/site-management.reducer';
import { SyncModalService } from '../services/sync-modal.service';
import { Observable, Subject } from 'rxjs';
import { siteSyncSelectors } from '../store/site-sync/site-sync.selectors';
import { SiteManagementSettingsDrawerComponent } from '../components/experience-template/site-management-settings-drawer/site-management-settings-drawer.component';
import { geoFixerEntities } from '../store/geo-fixer/geo-fixer.selectors';
import { SiteManagementSiteInfoCreatorModalComponent } from '../components/site-management-site-info-creator-modal/site-management-site-info-creator-modal.component';
import { Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { SITE_MANAGEMENT_ROLES } from '../models/roles-permissions.constant';
import { SiteManagementService } from '../services/site-management.service';

@Component({
  selector: 'amp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnDestroy {
  /** Indicates if a site sync job is active **/
  hasSyncJobInProgress$: Observable<boolean>;

  geoProblematicSitesCount$: Observable<number> = this._store.pipe(geoFixerEntities.geoProblematicSitesCount$);

  rolesRequiredToEdit = SITE_MANAGEMENT_ROLES.site;
  isAdmin$: Observable<boolean>;

  private _componentDestroyed$ = new Subject<void>();

  constructor(
    private _store: Store<ISiteManagementState>,
    private _syncModalService: SyncModalService,
    private _modalService: ModalService,
    private router: Router,
    private _overlay: Overlay,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    private _siteManagementService: SiteManagementService,
  ) {
    this.hasSyncJobInProgress$ = this._store.pipe(select(siteSyncSelectors.hasSyncJobInProgress));
    this.isAdmin$ = this._siteManagementService.hasAuthority$('appSettings');
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  exportCsvSites() {
    dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(
      take(1),
      takeUntil(this._componentDestroyed$)
    ).subscribe((dataSource) => {
      this._syncModalService.exportSites(dataSource);
    });
  }

  importCsvSites() {
    const modalRef = this._syncModalService.openSiteSyncModal([]);
    modalRef.componentInstance.downloadCsvSites
      .pipe(
        switchMap(() => {
          const sites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$), 1);
          return sites$.pipe(
            tap((sites) => {
              // export the list of sites selected as csv
              this._syncModalService.exportSites(sites);
            })
          );
        }),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe();
  }

  openSettingsDrawer(): void {
    this._modalService.openDrawer<SiteManagementSettingsDrawerComponent, any>(SiteManagementSettingsDrawerComponent, { closeOnBackdropClick: true });
  }

  openSiteCreationModal() {
    const modalRef = this._modalService.open(
      SiteManagementSiteInfoCreatorModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        width: '600px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      },
      ModalType.Dialog
    );

    modalRef.componentInstance.saved.pipe(
      takeUntil(modalRef.afterClosed)
    ).subscribe((siteId) => {
      // Redirect to editor page once site is created
      this.router.navigate([...this._siteManagementConfig.moduleBasePath, siteId]);
    });
  }
}
