import { ModalService } from '@activia/ngx-components';
import { Injectable } from '@angular/core';

import { isObservable, Observable, of, switchMap } from 'rxjs';
import { SaveBeforeExitDialogComponent } from '../components/save-before-exit-dialog/save-before-exit-dialog.component';

export interface ISaveBeforeExit {
  /** Tell if the component holds unsaved changes */
  getChangeDetails: () => { hasUnsavedChanges: boolean; hasInvalidChanges?: boolean } | Observable<{ hasUnsavedChanges: boolean; hasInvalidChanges?: boolean }>;

  /** Function to save changes. Return true if saved successfully, false otherwise */
  save: () => Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class SaveBeforeExitGuard  {
  constructor(private _modalService: ModalService) {}

  canDeactivate(component: ISaveBeforeExit): Observable<boolean> {
    const changes = component.getChangeDetails();
    const changesDetail$ = isObservable(changes) ? changes : of(changes);

    return changesDetail$.pipe(
      switchMap((changeDetails) => {
        if (!changeDetails.hasUnsavedChanges) {
          return of(true);
        } else {
          // Open the modal asking user to confirm save or discard
          const modalRef = this._modalService.open<SaveBeforeExitDialogComponent, { hasInvalidChanges: boolean }>(SaveBeforeExitDialogComponent, {
            closeOnBackdropClick: false,
            data: { hasInvalidChanges: changeDetails.hasInvalidChanges }
          });
          return modalRef.componentInstance.actioned.pipe(
            switchMap((result) => {
              switch (result) {
                case 'save':
                  return component.save();
                case 'discard':
                  return of(true);
                case 'cancel':
                  return of(false);
              }
            })
          );
        }
      })
    );
  }
}
