import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from './router.actions';

@Injectable()
export class RouterEffects {
  /**
   * When router navigation occurs
   **/

  routerGo$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.RouterGo),
        map((action) => action.to),
        tap(({ path, query: queryParams, extras }) => {
          if (typeof path === 'string') {
            // add query params to url
            if (queryParams && Object.keys(queryParams).length > 0) {
              const params = Object.entries(queryParams).map(([param, value]) => `${param}=${value}`);
              path += `?${params.join('&')}`;
            }
            this.router.navigateByUrl(path, { ...extras });
          } else {
            this.router.navigate(<any[]>path, { queryParams, ...extras });
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * When router back navigation occurs
   **/

  navigateBack$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.RouterBack),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  /**
   * When router forward navigation occurs
   **/

  navigateForward$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.RouterForward),
        tap(() => this.location.forward())
      ),
    { dispatch: false }
  );

  constructor(private action$: Actions, private router: Router, private location: Location) {}
}
