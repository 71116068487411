import { IJsonSchema } from '@activia/json-schema-forms';
import { AsyncDataModule, AsyncDataState, ButtonModule, CoreModule, EmptyDataMessageModule, LayoutModule, MasterDetailModule, ModalService } from '@activia/ngx-components';
import { EngineTagLevel, FeaturesSharedTagOperationModule, IEngineTagKeyDesc, ITagChangeSummary, ITagOperationChange, TagKeyDetailComponent } from '@amp/tag-operation';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { ISaveBeforeExit } from '../../../guards/save-before-exit.guard';
import { SiteOrgpathStore } from './site-orgpath.store';
import { OrgpathAddTagModalComponent } from '../orgpath-add-tag-modal/orgpath-add-tag-modal.component';
import { IOrgPathNode } from '../orgpath.interface';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SiteManagementService } from '../../../services/site-management.service';
import { CommonModule } from '@angular/common';
import { OrgpathFormGroupComponent } from '../orgpath-form-group/orgpath-form-group.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SiteOrgpathNodeEditorComponent } from './site-orgpath-node-editor/site-orgpath-node-editor.component';
import { SiteOrgpathTreeComponent } from './site-orgpath-tree/site-orgpath-tree.component';

@Component({
  selector: 'amp-site-orgpath',
  templateUrl: './site-orgpath.component.html',
  styleUrls: ['./site-orgpath.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    TranslocoModule,
    FeaturesSharedTagOperationModule,
    MasterDetailModule,
    OrgpathFormGroupComponent,
    SiteOrgpathNodeEditorComponent,
    ButtonModule,
    EmptyDataMessageModule,
    SiteOrgpathTreeComponent,
    LayoutModule,
    ReactiveFormsModule,
    AsyncDataModule,
    TagKeyDetailComponent,
  ],
  providers: [SiteOrgpathStore],
})
export class SiteOrgpathComponent implements OnInit, OnDestroy, ISaveBeforeExit {
  siteOrgPathDef$: Observable<IOrgPathNode> = this.siteOrgpathStore.selectOrgPathDefinition$;
  siteTagsDefinition$: Observable<Record<string, IEngineTagKeyDesc>> = this.siteOrgpathStore.selectTagsDefinitions$;
  siteOrgPathState$: Observable<AsyncDataState> = this.siteOrgpathStore.selectLoadingState$;
  isSaved$: Observable<boolean>;
  hasErrors$: Observable<boolean>;
  componentDestroyed$: Subject<void> = new Subject();
  editable$: Observable<boolean>;

  /** Attributes used for animation in the detail panel */
  activeDetailView: { id: string };
  index = 0;
  previousNode: IOrgPathNode;
  activeNode: IOrgPathNode;

  engineTagLevel = EngineTagLevel;
  formGroup = new FormGroup({});

  constructor(private siteOrgpathStore: SiteOrgpathStore, private _modalService: ModalService, private _siteManagementService: SiteManagementService) {
    this.editable$ = this._siteManagementService.hasAuthority$('orgPath');
  }

  ngOnInit(): void {
    this.isSaved$ = combineLatest([this.siteOrgpathStore.selectIsSaved$, this.siteOrgpathStore.selectErrors$]).pipe(map(([isSaved, errors]) => isSaved || !!Object.keys(errors).length));
    this.hasErrors$ = this.siteOrgpathStore.selectErrors$.pipe(map((errors) => !!Object.keys(errors)?.length));

    // Manage switch view animation in detail
    this.siteOrgpathStore.selectedNode$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((newNode) => {
        if (newNode) {
          this.previousNode = this.activeNode;
          this.activeNode = newNode;
          this.index = (this.index + 1) % 2;
          this.activeDetailView = { id: this.index.toString() };
        } else {
          this.previousNode = this.activeNode = this.activeDetailView = undefined;
          this.index = 0;
        }
      });
  }

  getChangeDetails(): Observable<{ hasUnsavedChanges: boolean; hasInvalidChanges?: boolean }> {
    return this.siteOrgpathStore.selectErrors$.pipe(
      take(1),
      map((errors) => ({
        hasUnsavedChanges: this.siteOrgpathStore.hasUnsavedChanges(),
        hasInvalidChanges: !!Object.keys(errors).length,
      }))
    );
  }

  save(): Observable<boolean> {
    return this.siteOrgpathStore.saveDefinition();
  }

  getSchemaFromTagDesc(tags: Record<string, IEngineTagKeyDesc>): Record<string, IJsonSchema> {
    return Object.keys(tags).reduce((acc, curr) => {
      acc[curr] = tags[curr].schema as IJsonSchema;
      return acc;
    }, {} as Record<string, IJsonSchema>);
  }

  openEditTagPanel(): void {
    this.activeDetailView = { id: '2' };
  }

  getInvalidTag(): Observable<ITagChangeSummary[]> {
    return of([]);
  }

  addFirstNode(): void {
    this.siteOrgpathStore.addRootNode();
  }

  editTag(tag: { key: string; description: IEngineTagKeyDesc; operations: ITagOperationChange }): void {
    this.siteOrgpathStore.saveTag(tag);
  }

  goToCurrentNode(): void {
    this.activeDetailView = this.activeNode ? { id: this.index.toString() } : undefined;
  }

  openAddTagPanel(): void {
    const modalRef = this._modalService.open<OrgpathAddTagModalComponent, void>(
      OrgpathAddTagModalComponent,
      {
        showCloseIcon: true,
      },
      {
        width: '95vw',
        height: '95vh',
      }
    );

    modalRef.componentInstance.tagLevel = EngineTagLevel.SITE;

    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed), takeUntil(this.componentDestroyed$)).subscribe((newTag) => {
      this.siteOrgpathStore.addNewTag(newTag);
    });
  }

  onSaveSiteOrgPathDef(): void {
    this.siteOrgpathStore.saveDefinition();
  }

  openPreview(open: boolean) {
    if (open) {
      this.activeDetailView = { id: '3' };
    } else {
      this.goToCurrentNode();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
