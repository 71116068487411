import { IDataTableDataSort } from '@activia/ngx-components';
import { DEVICE_SORTABLE_FIELD_MAP, DEVICE_SORTABLE_MONITORED_VALUE } from '../model/device-columns-mapping';
import { DeviceProperty } from '../model/device-properties.enum';
import { MonitoredValue } from '../model/monitored-value.enum';
import { DeviceDTO } from '@activia/cm-api';

/** convert the datatable sort object to the sort expression required for api calls **/
export const getApiSortExpression = (sort: IDataTableDataSort): string => {
  if (!sort) {
    return null;
  }
  // is it a monitored value?
  if ((<string[]>Object.values(MonitoredValue)).includes(sort.id)) {
    return DEVICE_SORTABLE_MONITORED_VALUE + sort.id;
  }
  // update later for tags sorting when implemented in backend, for now return the device field if
  // any
  return DEVICE_SORTABLE_FIELD_MAP.get(sort.id as DeviceProperty);
};

export const hasDeviceInterface = (device: DeviceDTO): boolean =>
  (device?.deviceInterfaces || []).some((deviceInterface) => (deviceInterface?.interfaces || []).length > 0);

export const hasSpecificDeviceInterface = (device: DeviceDTO, deviceInterfaceName: string): boolean =>
  (device?.deviceInterfaces || []).some((deviceInterface) =>
    (deviceInterface?.interfaces || []).some((interfce) => interfce.name === deviceInterfaceName));
