import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as ExperienceTemplateActions from './experience-template.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessengerNotificationService } from '@amp/messenger';
import { ExperienceTemplateService } from '../../services/experience-template.service';

@Injectable()
export class ExperienceTemplateEffects {
  fetchExperienceTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperienceTemplateActions.FetchExperienceTemplates),
      switchMap(() => this.experienceTemplateService.getExperienceTemplates()),
      map((experienceTemplates) => ExperienceTemplateActions.FetchExperienceTemplatesSuccess({ experienceTemplates })),
      catchError((err) => of(ExperienceTemplateActions.FetchExperienceTemplatesFail({ errorMessage: err.message })))
    )
  );

  fetchExperienceTemplateFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExperienceTemplateActions.FetchExperienceTemplatesFail),
        tap(({ errorMessage }) => this.notificationService.showErrorMessage(errorMessage))
      ),
    { dispatch: false }
  );

  createExperienceTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperienceTemplateActions.CreateExperienceTemplate),
      switchMap(({ experienceTemplate }) => this.experienceTemplateService.addExperienceTemplate(experienceTemplate)),
      map((experienceTemplate) => ExperienceTemplateActions.CreateExperienceTemplateSuccess({ experienceTemplate })),
      catchError((err) => of(ExperienceTemplateActions.CreateExperienceTemplateFail({ errorMessage: err.message })))
    )
  );

  createExperienceTemplateFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExperienceTemplateActions.CreateExperienceTemplateFail),
        tap(({ errorMessage }) => this.notificationService.showErrorMessage(errorMessage))
      ),
    { dispatch: false }
  );

  deleteExperienceTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperienceTemplateActions.DeleteExperienceTemplate),
      switchMap(({ experienceTemplateId }) =>
        this.experienceTemplateService.deleteExperienceTemplate(experienceTemplateId).pipe(
          map(() => ExperienceTemplateActions.DeleteExperienceTemplateSuccess({ experienceTemplateId })),
          catchError((err) => of(ExperienceTemplateActions.DeleteExperienceTemplateFail({ errorMessage: err.message, experienceTemplateId })))
        )
      )
    )
  );

  deleteExperienceTemplateFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExperienceTemplateActions.DeleteExperienceTemplateFail),
        tap(({ errorMessage }) => this.notificationService.showErrorMessage(errorMessage))
      ),
    { dispatch: false }
  );

  editExperienceTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperienceTemplateActions.EditExperienceTemplate),
      switchMap(({ experienceTemplate }) =>
        this.experienceTemplateService.updateExperienceTemplate(experienceTemplate).pipe(
          map(() => ExperienceTemplateActions.EditExperienceTemplateSuccess({ experienceTemplate })),
          catchError((err) => of(ExperienceTemplateActions.EditExperienceTemplateFail({ errorMessage: err.message })))
        )
      )
    )
  );

  editExperienceTemplateFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExperienceTemplateActions.EditExperienceTemplateFail),
        tap(({ errorMessage }) => this.notificationService.showErrorMessage(errorMessage))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private experienceTemplateService: ExperienceTemplateService, private notificationService: MessengerNotificationService) {}
}
