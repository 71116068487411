import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoadingState, ThemeType } from '@activia/ngx-components';
import { BehaviorSubject, filter, map, Observable, ReplaySubject } from 'rxjs';
import { ISiteMonitoringKeyMetricViewerData } from '../../../model/site-monitoring-key-metric-viewer-data.interface';
import {
  IKeyMetricSiteEnclosureStatus,
  SiteMonitoringKeyMetricEnclosureStatus
} from '../../../model/key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import { AlarmEvent, AlarmEventLevel, AlarmEvents, AlarmLevelsInfo } from '@amp/devices';
import { share } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

type KeyMetricEnclosureStatus = Record<SiteMonitoringKeyMetricEnclosureStatus, { count: number; level: AlarmEventLevel; description: string }>;

@Component({
  selector: 'amp-key-metrics-enclosure-status',
  templateUrl: './key-metrics-enclosure-status.component.html',
  styleUrls: ['./key-metrics-enclosure-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyMetricsEnclosureStatusComponent implements OnInit, OnChanges {

  @Input() keyMetric: ISiteMonitoringKeyMetricViewerData;

  /** Indicates if the card should show the compact view */
  @Input() showCompactView = false;

  enclosureStatus$: Observable<{ count: KeyMetricEnclosureStatus; theme: ThemeType; icon: string }>;
  enclosureStatusDataState$: Observable<LoadingState>;

  i18nMessages: { [key: string]: { [key: string]: string } } = {
    [AlarmEvent.FanError]: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FANERROR.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FANERROR.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FANERROR.MESSAGE.PLURAL_30',
    },
    [AlarmEvent.FilterService]: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FILTERSERVICE.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FILTERSERVICE.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.FILTERSERVICE.MESSAGE.PLURAL_30',
    },
    [AlarmEvent.LatchBypass]: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.LATCHBYPASS.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.LATCHBYPASS.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.LATCHBYPASS.MESSAGE.PLURAL_30',
    },
    [AlarmEvent.DoorOpened]: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.DOOROPENED.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.DOOROPENED.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.DOOROPENED.MESSAGE.PLURAL_30',
    },
    [AlarmEvent.ThermalTrip]: {
      '=0': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.THERMALTRIP.MESSAGE.SINGULAR_30',
      '=1': 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.THERMALTRIP.MESSAGE.SINGULAR_30',
      other: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.THERMALTRIP.MESSAGE.PLURAL_30',
    },
  };

  AlarmLevelsInfo = AlarmLevelsInfo;

  private _enclosureStatus: { count: KeyMetricEnclosureStatus; theme: ThemeType; icon: string };
  private _keyMetricSubject = new BehaviorSubject<IKeyMetricSiteEnclosureStatus>(null);
  private _keyMetric$: Observable<IKeyMetricSiteEnclosureStatus> = this._keyMetricSubject.asObservable();

  constructor(public _elementRef: ElementRef, private _translocoService: TranslocoService) {}

  ngOnChanges({ keyMetric }: SimpleChanges) {
    if (keyMetric && keyMetric.currentValue) {
      this._keyMetricSubject.next(keyMetric.currentValue.data);
    }
  }

  ngOnInit() {
    this.enclosureStatus$ = this._keyMetric$.pipe(
      filter((status) => !!status),
      map((keyMetric) => {
        const isStatusOk = keyMetric.level == null ||
          this.keyMetric.config.customConfig.optimisticViewEnabled && keyMetric.level === AlarmEventLevel.Info;

        const statuses: KeyMetricEnclosureStatus = Object.keys(keyMetric.count).reduce((res, curr) => {
          const event = AlarmEvents.find((ae) => ae.id === curr);
          return {
            ...res,
            [curr]: {
              ...keyMetric.count[curr],
              description: [
                event?.i18n?.dmbMessage ? this._translocoService.translate(event.i18n.dmbMessage) : null,
                event?.i18n?.dmbAction ? this._translocoService.translate(event.i18n.dmbAction) : null,
              ].filter((message) => !!message).join('. '),
            }
          };
        }, {} as KeyMetricEnclosureStatus);

        this._enclosureStatus = {
          count: statuses,
          icon: isStatusOk ? 'action:check_circle' : AlarmLevelsInfo[keyMetric.level].icon,
          theme: isStatusOk ? ThemeType.SUCCESS : AlarmLevelsInfo[keyMetric.level].theme,
        };
        return this._enclosureStatus;
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this.enclosureStatusDataState$ = this.enclosureStatus$.pipe(
      map((status) => status === null ? LoadingState.LOADING : LoadingState.LOADED),
    );
  }

  /** A function that indicates if the key metric data is empty **/
  emptyEnclosureStatusFn(): boolean {
    return !this._enclosureStatus;
  }
}
