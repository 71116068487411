import { createReducer, on } from '@ngrx/store';
import * as BoardOrgPathDefinitionActions from './board-org-path-definition.actions';
import { IOrgPathDefNode, IOrgPathDefRoot } from '../../model/board-org-path-definition.interface';
import { AsyncData, AsyncDataState, LoadingState } from '@activia/ngx-components';
import { IJsonSchema } from '@activia/json-schema-forms';

export const boardOrgPathDefinitionsFeatureKey = 'boardOrgPathDefinitions';

export interface BoardOrgPathDefState {
  orgPathDefinition: AsyncData<IOrgPathDefRoot>;
  tagKeys: Record<string, IJsonSchema>;
  errorFetchOrgPath: any;
  orgPathSaveState: AsyncDataState; // Used to know if orgpath has been saved successfully
}

export const initialState: BoardOrgPathDefState = {
  orgPathDefinition: {
    data: undefined,
    state: LoadingState.INIT,
  },
  errorFetchOrgPath: undefined,
  tagKeys: {},
  orgPathSaveState: LoadingState.INIT,
};

export const reducer = createReducer(
  initialState,

  //#region Fetch
  on(BoardOrgPathDefinitionActions.FetchBoardOrgPathDefinition, (state) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADING,
      lastAttempt: new Date(),
      data: null,
    },
    tagKeys: {},
  })),

  on(BoardOrgPathDefinitionActions.FetchBoardOrgPathDefinitionSuccess, (state, action) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
      data: action.boardOrgPathDefinition,
    },
    tagKeys: action.tagKeys,
  })),

  on(BoardOrgPathDefinitionActions.FetchBoardOrgPathDefinitionFail, (state, action) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADED,
      data: { type: 'board', root: {} as IOrgPathDefNode }, // If error we fallback to an empty orgpath Definition
    },
    errorFetchOrgPath: action.error,
    tagKeys: {},
  })),
  //#endregion

  //#region Save

  on(BoardOrgPathDefinitionActions.SaveBoardOrgPathDefinition, (state) => ({
    ...state,
    orgPathSaveState: LoadingState.LOADING,
  })),

  on(BoardOrgPathDefinitionActions.SaveBoardOrgPathDefinitionSuccess, (state, action) => ({
    ...state,
    orgPathDefinition: {
      ...state.orgPathDefinition,
      state: LoadingState.LOADED,
      lastSuccess: new Date(),
      data: action.boardOrgPathDefinition,
    },
    tagKeys: action.tagKeys,
    orgPathSaveState: LoadingState.LOADED,
  })),

  on(BoardOrgPathDefinitionActions.SaveBoardOrgPathDefinitionFail, (state, action) => ({
    ...state,
    orgPathSaveState: action.error,
  }))
  //#endregion
);
