import { createAction, props } from '@ngrx/store';
import { IExperienceTemplate } from '../../models/experience-template.interface';

export const FetchExperienceTemplates = createAction('[Experience Template] Fetch Experience Templates');
export const FetchExperienceTemplatesSuccess = createAction('[Experience Template] Fetch Experience Templates Success', props<{ experienceTemplates: IExperienceTemplate[] }>());
export const FetchExperienceTemplatesFail = createAction('[Experience Template] Fetch Experience Templates Fail', props<{ errorMessage: any }>());

export const DeleteExperienceTemplate = createAction('[Experience Template] Delete Experience Template', props<{ experienceTemplateId: string }>());
export const DeleteExperienceTemplateSuccess = createAction('[Experience Template] Delete Experience Template Success', props<{ experienceTemplateId: string }>());
export const DeleteExperienceTemplateFail = createAction('[Experience Template] Delete Experience Template Fail', props<{ errorMessage: any; experienceTemplateId: string }>());

export const EditExperienceTemplate = createAction('[Experience Template] Edit Experience Template', props<{ experienceTemplate: IExperienceTemplate }>());
export const EditExperienceTemplateSuccess = createAction('[Experience Template] Edit Experience Template Success', props<{ experienceTemplate: IExperienceTemplate }>());
export const EditExperienceTemplateFail = createAction('[Experience Template] Edit Experience Template Fail', props<{ errorMessage: any }>());

export const CreateExperienceTemplate = createAction('[Experience Template] Create Experience Template', props<{ experienceTemplate: IExperienceTemplate }>());
export const CreateExperienceTemplateSuccess = createAction('[Experience Template] Create Experience Template Success', props<{ experienceTemplate: IExperienceTemplate }>());
export const CreateExperienceTemplateFail = createAction('[Experience Template] Create Experience Template Fail', props<{ errorMessage: any }>());
