import { AuthenticationService, RoleDTO, ScopeDTO, CurrentUserService, RoleWithScopesDTO } from '@activia/cm-api';
import { Configuration } from '@activia/cm-api';
import { Configuration as DeviceScreenshotAPIConfiguration } from '@activia/device-screenshot-api';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { MessengerNotificationService } from '@amp/messenger';
import { ApiTokenService } from '../api-token.service';

import * as AuthAction from './auth.action';

@Injectable()
export class AuthEffects {
  /**
   * Checks for a session when the application is initiated on any page.
   */

  onInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.AuthInitiated),
      map((action) => {
        // check the local storage to see if there is an api token
        const token = this.apiTokenService.getApiToken();
        if (token) {
          this.configuration.apiKeys = { Authorization: 'SID ' + token };
          this.deviceScreenshotAPIConfiguration.apiKeys = { sessionId: token };
          return [action, token];
        } else {
          throwError('session does not exist');
        }
        return [action, token];
      }),
      exhaustMap(([, token]) =>
        this.authService.getPingKeepAlive().pipe(
          map(() => AuthAction.AppSessionReady({ token: token.toString() })),
          catchError((_) => of(AuthAction.LoginIncomplete({ error: 'session is not ready.' })))
        )
      )
    )
  );

  /**
   * user initiated login action
   */

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.LoginInitiated),
      tap((credentials) => {
        this.configuration.apiKeys = {};
        this.configuration.username = credentials.username;
        this.configuration.password = credentials.password;
      }),
      exhaustMap((_) =>
        this.authService.getSessionId().pipe(
          tap((user) => {
            this.configuration.apiKeys = { Authorization: 'SID ' + user.sessionId };
            this.deviceScreenshotAPIConfiguration.apiKeys = { sessionId: user.sessionId };
            // set the token in the local storage
            this.apiTokenService.setApiToken(user.sessionId);
          }),
          map((user) => AuthAction.LoginSuccess({ authenticatedUser: user })),
          catchError((err) => {
            const errMsg = err.status === 0 ? 'error.server-unreachable' : 'auth.error.login-fail';
            this.messengerNotificationService.showErrorMessage(errMsg);
            return of(AuthAction.LoginFail(err));
          })
        )
      )
    )
  );

  /**
   * The login success effect is responsible for retreiving all the user information once a login
   * occurs or a valid session is found. Handles the sequencing of calls to ensure that the user
   * scopes, roles, deviceGroups are all available in the app.
   */

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.LoginSuccess, AuthAction.AppSessionReady),
      exhaustMap((_) =>
        forkJoin([this.currentUserService.getCurrentUserInfo(), this.currentUserService.getPermissionsCurrentUser(), this.currentUserService.getRoleWithScopesListForCurrentUser()]).pipe(
          switchMap(([usr, perms, roleWithScopes]) => {
            const roles: RoleDTO[] = [];
            let scopes: Record<number, ScopeDTO> = {};

            // build the array of roles and scopes
            roleWithScopes.forEach((roleWScopes: RoleWithScopesDTO) => {
              roles.push({
                id: roleWScopes.id,
                name: roleWScopes.name,
              });
              // Build scopes without duplicate
              roleWScopes.scopes.forEach((scope) => {
                scopes = { ...scopes, [scope.id]: scope };
              });
            });

            // if the user is logged in, also fetch the user language from the
            // preferences
            return of(
              AuthAction.LoginComplete({
                userInfo: usr,
                permissions: perms.operationNames,
                userRoles: roles,
                scopes: Object.values(scopes),
              })
            ); // In future only one role per user is allowed
          }),
          catchError((err) => {
            // TODO: add GA tracking back once decoupling task is completed
            this.messengerNotificationService.showErrorMessage('auth.error.login-no-user-info');
            return of(AuthAction.LoginIncomplete({ error: err }));
          })
        )
      )
    )
  );

  /**
   * when the user log's out or session has expired
   */

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.Logout, AuthAction.AuthExpired, AuthAction.LogoutFromTabSync),
      exhaustMap((action: Action) => {
        // if we logout from a tab sync, we dont need to call the logout session id again because it
        // will already have been called by the initial logout
        const observable$ = action.type === AuthAction.LogoutFromTabSync.type ? of(true) : this.authService.logoutSessionId();
        return observable$.pipe(
          map((_) => {
            // close all services and redirect to the login screen
            this.onLogout();

            // redirect to login page
            return AuthAction.LogoutSuccess();
          }),
          catchError((err) => {
            this.onLogout();
            if (err.status === 0) {
              this.messengerNotificationService.showErrorMessage('error.server-unreachable');
            }
            // TODO: add GA tracking back once decoupling task is completed
            // even if there was an error, close all services and redirect to the login screen
            return of(AuthAction.LogoutFail({ error: err }));
          })
        );
      })
    )
  );

  /** Called when the logout is completed **/
  onLogout() {
    // clear configuration credentials
    this.configuration.apiKeys = undefined;
    this.configuration.username = undefined;
    this.configuration.password = undefined;

    // remove token from local storage
    this.apiTokenService.removeApiToken();
  }

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private currentUserService: CurrentUserService,
    private configuration: Configuration,
    private deviceScreenshotAPIConfiguration: DeviceScreenshotAPIConfiguration,
    private apiTokenService: ApiTokenService,
    private messengerNotificationService: MessengerNotificationService
  ) {}
}
