<ng-container
  *avnAsyncData="enclosureStatus$ | async;
  state: enclosureStatusDataState$ | async;
  emptyTemplate: showCompactView ? null : emptyTemplate;
  loadingTemplate: loadingTemplate;
  emptyFn: emptyEnclosureStatusFn.bind(this)"
>
  <ng-container *ngIf="!showCompactView; else compactViewTemplate">
    <avn-summary-card
      [header]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.TITLE_20' | transloco"
      [footer]="keyMetric.date ? ('siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.LAST_UPDATE_20' | transloco: { date: keyMetric?.date | timeAgo | async }) : null"
      [height]="'200px'"
      [width]="'200px'"
      [avnTheme]="(enclosureStatus$ | async).theme"
      [icon]="(enclosureStatus$ | async).icon"
    >
      <div
        class="enclosure-status"
        *ngFor="let status of (enclosureStatus$ | async).count | keyvalue"
        [avnTooltip]="keyMetric.config.customConfig.showTooltip ? status.value.description : null"
        [tooltipPlacement]="'bottom'"
      >
        {{ status.value.count | i18nPlural: i18nMessages[status.key] | transloco: { count: status.value.count } }}
      </div>
    </avn-summary-card>
  </ng-container>

  <ng-template #compactViewTemplate>
    <amp-key-metrics-accordion
      class="key-metrics-content"
      [keyMetricsAccordionBorderColor]="(enclosureStatus$ | async).theme"
      [keyMetricsAccordionHeader]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.TITLE_20' | transloco"
      [keyMetricsAccordionContent]="accordionTemplate"
    ></amp-key-metrics-accordion>
  </ng-template>
</ng-container>

<ng-template #accordionTemplate>
  <ng-container *ngFor="let status of (enclosureStatus$ | async).count | keyvalue">
    <div
      class="accordion-content"
      [avnTooltip]="keyMetric.config.customConfig.showTooltip ? status.value.description : null"
      [tooltipPlacement]="'bottom'"
    >
      <div class="item">
        <span>{{ 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.STATUS.' + status.key.toUpperCase() + '.TITLE_35' | transloco }}</span>
      </div>
      <div class="item right">
        <avn-badge
          badgeSize="small"
          [avnTheme]="status.value.level !== null ? AlarmLevelsInfo[status.value.level].theme : 'success'"
          [label]="status.value.level !== null ?
            (('siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.ENCLOSURE_STATUS.ALARM_LEVEL.' + status.value.level + '_15' | transloco) + ' - ' + status.value.count) :
            'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.ENCLOSURE_STATUS.ALARM_LEVEL.OK_15' | transloco"
        ></avn-badge>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyTemplate>
  <ng-container *ngIf="!showCompactView">
    <avn-skeleton
      data-e2e="empty"
      [style.height.px]="_elementRef.nativeElement.parentElement.clientHeight"
      [style.width.px]="_elementRef.nativeElement.parentElement.clientHeight"
      [animate]="false"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS.KEY_METRICS.ENCLOSURE_STATUS.NOT_AVAILABLE_125' | transloco"
      [skeletonType]="'CUSTOM_SVG'"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80"></svg>
    </avn-skeleton>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="key-metric-loading-container">
    <h6 class="title">{{ 'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.ENCLOSURE_STATUS.TITLE_35' | transloco }}</h6>
    <avn-skeleton
      data-e2e="loading"
      [animate]="true"
      [animateDirection]="'VERTICAL'"
      [text]="'siteMonitoringSharedScope.SITE_MONITORING.KEY_METRICS.KEY_METRIC_DATA_LOADING_TEXT_50' | transloco"
      [skeletonType]="'CARD'"
    >
    </avn-skeleton>
  </div>
</ng-template>
