import { CountAggregationData, ISiteMonitoringData } from '../model/site-monitoring-data.interface';
import { ISiteMonitoringKeyMetricConfig } from '../model/site-monitoring-key-metric-config.interface';
import { SiteMonitoredValue } from '../model/site-monitored-value.enum';
import { SiteMonitoringKeyMetric } from '../model/site-monitoring-key-metric.enum';
import { OptimisticViewPipe } from '../pipes/optimistic-view.pipe';
import { ISiteMonitoringKeyMetricServiceSettings } from '../model/key-metrics/site-monitoring-key-metric-service-settings.interface';
import {
  AlarmEvent,
  AlarmEventLevel,
  AlarmEventNamespace,
  ContentStatus,
  HealthStatusCode,
  ServiceStatusCode
} from '@amp/devices';
import { ISiteMonitoringKeyMetricViewerData } from '../model/site-monitoring-key-metric-viewer-data.interface';
import {
  codCardConfig,
  contentStatusCardConfig,
  healthSummaryKeyMetricConfig,
  posCardConfig
} from '../components/keymetrics/key-metric-viewer-config.constant';
import { BoardDTO, MonitoringAlarmEventDTO } from '@activia/cm-api';
import { getBoardDeviceIds, getBoardsDeviceIds } from './site-boards.utils';
import { IDeviceAlarm } from '../model/alarm-event.interface';
import { getSiteMonitoringKeyMetricEnclosureAlarmEvents, KeyMetricEnclosureStatusCount } from '../model/key-metrics/site-monitoring-key-metric-enclosure-status-settings.interface';
import { IDeviceInfo } from '../components/site-monitoring-detail/store/site-monitoring-detail.model';
import { ALARM_EVENT_OPTIMISTIC_COMBINATIONS } from '../model/optimistic-view-status-combinations';

/** Get the monitoring values of health summary **/
export const getHealthSummaryStatusData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  monitoringData: ISiteMonitoringData,
  optimisticViewEnabled: boolean,
  hideKeyMetricOnOk: boolean
): ISiteMonitoringKeyMetricViewerData => {
  let healthStatusData = monitoringData[SiteMonitoredValue.HealthStatus] || {};
  if (optimisticViewEnabled) {
    healthStatusData = new OptimisticViewPipe().transform(healthStatusData, true, SiteMonitoredValue.HealthStatus);
  }

  const statuses = Object.keys(healthStatusData);
  return hideKeyMetricOnOk && statuses.length === 1 && +statuses[0] === HealthStatusCode.OK ?
    null :
    {
      id: keyMetric.id,
      config: keyMetric,
      data: healthStatusData,
      date: healthSummaryKeyMetricConfig.date?.field ? monitoringData[healthSummaryKeyMetricConfig.date?.field] : null
    } as ISiteMonitoringKeyMetricViewerData;
};

/** Get the monitoring values of services **/
export const getKeyMetricsServicesData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  monitoringData: ISiteMonitoringData,
  servicesConfig: ISiteMonitoringKeyMetricServiceSettings,
  hideKeyMetricOnOk: boolean,
): ISiteMonitoringKeyMetricViewerData => {
  // should show status in order: Player, Http, Omnicast
  const services = servicesConfig?.services || [];
  const orderedServices = [
    ...(services.includes(SiteMonitoredValue.ServicePlayer) ? [SiteMonitoredValue.ServicePlayer] : []),
    ...(services.includes(SiteMonitoredValue.HttpService) ? [SiteMonitoredValue.HttpService] : []),
    ...(services.includes(SiteMonitoredValue.OmnicastStatus) ? [SiteMonitoredValue.OmnicastStatus] : []),
  ].reduce((dataSource, service) => {
    // create an entry for each service (even if it has no data)
    const serviceData = <CountAggregationData<ServiceStatusCode, number>>monitoringData[service];
    return {
      ...dataSource,
      [`${service}`]: serviceData || {},
    };
  }, {});

  const visibleServices = Object.keys(orderedServices).filter((serviceStatus) => {
    const statuses = Object.keys(orderedServices[serviceStatus]);
    return hideKeyMetricOnOk && statuses.length === 1 && +statuses[0] === ServiceStatusCode.OK ? false : true;
  });
  return visibleServices.length > 0 ?
    {
      id: keyMetric.id,
      config: keyMetric,
      data: visibleServices.reduce((acc, service) => ({ ...acc, [`${service}`]: orderedServices[service] }), {})
    } as ISiteMonitoringKeyMetricViewerData :
    null;
};

/** Get monitoring value for content status, COD state and POS state */
export const getKeyMetricsStatusData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  monitoringData: ISiteMonitoringData,
  field: SiteMonitoredValue,
  optimisticViewEnabled: boolean,
  hideKeyMetricOnOk: boolean,
  dateField: SiteMonitoredValue,
  okStatus: ContentStatus | ServiceStatusCode,
): ISiteMonitoringKeyMetricViewerData => {
  let data: CountAggregationData = monitoringData[field] as CountAggregationData || {};

  if (optimisticViewEnabled) {
    data = new OptimisticViewPipe().transform(data, true, field);
  }

  const statuses = Object.keys(data);
  return hideKeyMetricOnOk && statuses.length === 1 && +statuses[0] === okStatus ?
    null :
    {
      id: keyMetric.id,
      config: keyMetric,
      data,
      date: dateField ? monitoringData[dateField] : null
    } as ISiteMonitoringKeyMetricViewerData;
};

/** Get the key metric data for enclosure status **/
export const getKeyMetricsEnclosureStatusData = (
  keyMetric: ISiteMonitoringKeyMetricConfig,
  optimisticViewEnabled: boolean,
  hideKeyMetricOnOk: boolean,
  alarms: MonitoringAlarmEventDTO[],
  boards: BoardDTO[],
  devices: Partial<IDeviceInfo>[]
): ISiteMonitoringKeyMetricViewerData => {

  // Filter out only the alarms needed for enclosure status
  const enclosureEvents = getSiteMonitoringKeyMetricEnclosureAlarmEvents();
  const alarmEventNames = enclosureEvents.flatMap((event) => event.name);
  const alarmEvents: MonitoringAlarmEventDTO[] = alarms.filter((alarm) =>
    alarm.namespace.toLowerCase() === AlarmEventNamespace.Enclosure.toLowerCase() &&
    alarmEventNames.includes(alarm.name as any));

  // Filter out boards that have no CONF_ENCLOSURE_TYPE
  const enclosures = boards.filter((board) => {
    const devIds = getBoardDeviceIds(board);
    return devices.filter((device) => devIds.includes(device.device.id))
      .some((device) => device.monitoringData.CONF_ENCLOSURE_TYPE);
  });

  // Get all devices in enclosures
  const deviceIds = getBoardsDeviceIds(enclosures).sort((a, b) => a - b);

  // Group alarms by the above devices
  const deviceAlarms: Array<Partial<IDeviceAlarm>> = deviceIds.map((deviceId) => {
    const alarmsParsed = alarmEvents
      .filter((alarm) => alarm.deviceId === deviceId)
      // Apply optimisticViewEnabled if set to true
      .map((alarm) => {
        if (optimisticViewEnabled) {
          const optimizedLevel = ALARM_EVENT_OPTIMISTIC_COMBINATIONS.get(alarm.level);
          return optimizedLevel ? { ...alarm, level: optimizedLevel } : alarm;
        } else {
          return alarm;
        }
      })
      .sort((a, b) => a.level - b.level);

    return { deviceId, alarms: alarmsParsed };
  });

  const getHighestAlarmLevel = (aLevel, bLevel) => {
    if (aLevel != null && bLevel != null) {
      return aLevel < bLevel ? aLevel : bLevel;
    }
    return aLevel != null ? aLevel : bLevel;
  };

  /**
   * Group alarms by each enclosure
   * E.g.
   * Enclosure 1
   *   - Door open: { count: 1, level: Emergency }
   *   - Latch Bypass: { count: 1, level: Error }
   * Enclosure 2
   *   - Door open: { count: 1, level: Critical }
   */
  const enclosuresAlarms: Record<string, { count: number; level: AlarmEventLevel }>[] = enclosures.map((enclosure) => {
    // Get devices in this enclosure
    const devIds = getBoardDeviceIds(enclosure).sort((a, b) => a - b);

    // Get alarms found in the devices in this enclosure and group by alarm event type
    const enclosureAlarms = deviceAlarms
      .filter((da) => devIds.includes(da.deviceId))
      .flatMap((da) => da.alarms);

    // Get status if a matching alarm event for an enclosure status is found
    // At this point the alarms are enclosure specific alarm, just need to set count in the matching field in the status
    // The status is per enclosure, not per device. Hence, even if an enclosure has 3 devices, and all 3 devices
    // have 'dooropen' error, the count is still 1. For the alarm level, take the highest level
    const enclosureAlarmEvents: Record<string, { count: number; level: AlarmEventLevel }> = {};
    enclosureAlarms.forEach((enclosureAlarm) => {
      enclosureAlarmEvents[enclosureAlarm.name] = enclosureAlarmEvents[enclosureAlarm.name] ?
        { count: 1, level: getHighestAlarmLevel(enclosureAlarm.level, enclosureAlarmEvents[enclosureAlarm.name].level) } :
        { count: 1, level: enclosureAlarm.level };
    });

    return enclosureAlarmEvents;
  });

  /**
   * Get summarized enclosure statuses of this site
   * E.g. Door open: { count: 2, level: Emergency }
   *      Latch Bypass: { count: 1, level: Error }
   */
  let enclosuresStatuses: Partial<KeyMetricEnclosureStatusCount> = enclosuresAlarms.reduce((res, curr) => {
    Object.keys(curr).forEach((status) => {
      const eventId = enclosureEvents.find((event) => event.name.includes(status)).id;
      res[eventId] = {
        count: res[eventId].count + curr[status].count, // Collect count of each alarm type from all enclosures
        level: getHighestAlarmLevel(curr[status].level, res[eventId].level), // Get highest alarm level of each alarm type
      } ;
    });
    return res;
  }, {
    [AlarmEvent.DoorOpened]: { count: 0, level: null },
    [AlarmEvent.FanError]: { count: 0, level: null },
    [AlarmEvent.FilterService]: { count: 0, level: null },
    [AlarmEvent.LatchBypass]: { count: 0, level: null },
    [AlarmEvent.ThermalTrip]: { count: 0, level: null },
  } as any);

  const selectedAlarmEvents = keyMetric.customConfig.enclosureStatus;
  // Filter out alarm event that is either not selected to show or has 0 count
  enclosuresStatuses = Object.keys(enclosuresStatuses).reduce((res, curr) =>
    selectedAlarmEvents.includes(curr) && enclosuresStatuses[curr].count > 0 ? { ...res, [curr]: enclosuresStatuses[curr] } : { ...res }, {});

  // Apply hideKeyMetricOnOk if it's set to true
  const isVisible = Object.keys(enclosuresStatuses).some((status) => enclosuresStatuses[status].count > 0 &&
    (hideKeyMetricOnOk ? enclosuresStatuses[status].level < AlarmEventLevel.Info : true));

  // Get the highest alarm level from all enclosures in this site
  const siteAlarmLevel = Object.values(enclosuresStatuses).map((status) => status.level)
    .reduce((res, curr) => getHighestAlarmLevel(res, curr), null);

  return isVisible ? {
    id: keyMetric.id,
    config: keyMetric,
    data: { count: enclosuresStatuses, level: siteAlarmLevel },
    date: (new Date()).toISOString(),
  } as ISiteMonitoringKeyMetricViewerData : null;
};

/** If a key metric has hideKeyMetricsOnOk on and it is in ok state, it should be hidden */
export const getVisibleKeyMetrics = (
  keyMetrics: ISiteMonitoringKeyMetricConfig[],
  monitoringData: ISiteMonitoringData,
  alarms: MonitoringAlarmEventDTO[],
  boards: BoardDTO[],
  devices: Partial<IDeviceInfo>[]
): ISiteMonitoringKeyMetricViewerData[] => {
  const visibleKeyMetric = keyMetrics.map((keyMetric) => {
    const optimisticViewEnabled = keyMetric?.customConfig?.optimisticViewEnabled || false;
    const hideKeyMetricOnOk = keyMetric?.customConfig?.hideKeyMetricOnOk || false;

    switch (keyMetric.id) {
      case SiteMonitoringKeyMetric.HealthStatusSummary:
        return getHealthSummaryStatusData(keyMetric, monitoringData, optimisticViewEnabled, hideKeyMetricOnOk);

      case SiteMonitoringKeyMetric.ContentStatus:
        return getKeyMetricsStatusData(keyMetric, monitoringData, SiteMonitoredValue.ContentStatus, optimisticViewEnabled, hideKeyMetricOnOk, contentStatusCardConfig.date?.field, ContentStatus.OK);

      case SiteMonitoringKeyMetric.Services:
        return getKeyMetricsServicesData(keyMetric, monitoringData, keyMetric?.customConfig, hideKeyMetricOnOk);

      case SiteMonitoringKeyMetric.CodState:
        return getKeyMetricsStatusData(keyMetric, monitoringData, SiteMonitoredValue.CodState, optimisticViewEnabled, hideKeyMetricOnOk, codCardConfig.date?.field, ServiceStatusCode.OK);

      case SiteMonitoringKeyMetric.PosState:
        return getKeyMetricsStatusData(keyMetric, monitoringData, SiteMonitoredValue.PosState, optimisticViewEnabled, hideKeyMetricOnOk, posCardConfig.date?.field, ServiceStatusCode.OK);

      case SiteMonitoringKeyMetric.EnclosureStatus:
        return getKeyMetricsEnclosureStatusData(keyMetric, optimisticViewEnabled, hideKeyMetricOnOk, alarms, boards, devices);

      default:
        return { id: keyMetric.id, config: keyMetric, data: monitoringData[keyMetric.id] } as ISiteMonitoringKeyMetricViewerData;
    }
  }).filter((keyMetric) => keyMetric);

  return visibleKeyMetric;
};

export const hasVisibleKeyMetrics = (visileKeyMetrics: ISiteMonitoringKeyMetricViewerData[]): boolean => visileKeyMetrics.some((visileKeyMetric) => visileKeyMetric.id === SiteMonitoringKeyMetric.Services ?
    Object.values(visileKeyMetric.data).some((servceData) => !!servceData) :
    !!visileKeyMetric.data);
