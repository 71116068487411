import { IJsonSchema } from '@activia/json-schema-forms';
import { AsyncDataModule, AsyncDataState, ButtonModule, CoreModule, EmptyDataMessageModule, LayoutModule, MasterDetailModule, ModalService } from '@activia/ngx-components';
import { EngineTagLevel, FeaturesSharedTagOperationModule, IEngineTagKeyDesc, ITagChangeSummary, ITagOperationChange, TagKeyDetailComponent } from '@amp/tag-operation';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { ISaveBeforeExit } from '../../../guards/save-before-exit.guard';
import { BoardOrgpathStore } from './board-orgpath.store';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OrgpathAddTagModalComponent } from '../orgpath-add-tag-modal/orgpath-add-tag-modal.component';
import { IOrgPathNode } from '../orgpath.interface';
import { SiteManagementService } from '../../../services/site-management.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { OrgpathFormGroupComponent } from '../orgpath-form-group/orgpath-form-group.component';
import { BoardOrgpathTreeComponent } from './board-orgpath-tree/board-orgpath-tree.component';
import { BoardOrgpathNodeEditorComponent } from './board-orgpath-node-editor/board-orgpath-node-editor.component';

@Component({
  selector: 'amp-board-orgpath',
  templateUrl: './board-orgpath.component.html',
  styleUrls: ['./board-orgpath.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    TranslocoModule,
    FeaturesSharedTagOperationModule,
    MasterDetailModule,
    OrgpathFormGroupComponent,
    ButtonModule,
    EmptyDataMessageModule,
    LayoutModule,
    ReactiveFormsModule,
    AsyncDataModule,
    BoardOrgpathTreeComponent,
    BoardOrgpathNodeEditorComponent,
    TagKeyDetailComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BoardOrgpathStore],
})
export class BoardOrgpathComponent implements OnInit, OnDestroy, ISaveBeforeExit {
  boardOrgPathDef$: Observable<IOrgPathNode> = this._boardOrgPathStore.selectOrgPathDefinition$;
  boardTagsDefinition$: Observable<Record<string, IEngineTagKeyDesc>> = this._boardOrgPathStore.selectTagsDefinitions$;
  boardOrgPathState$: Observable<AsyncDataState> = this._boardOrgPathStore.selectLoadingState$;
  isSaved$: Observable<boolean>;
  hasErrors$: Observable<boolean>;
  componentDestroyed$: Subject<void> = new Subject();
  editable$: Observable<boolean>;
  /** Attributes used for animation in the detail panel */
  activeDetailView: { id: string };
  index = 0;
  previousNode: IOrgPathNode;
  activeNode: IOrgPathNode;

  engineTagLevel = EngineTagLevel;

  formGroup = new FormGroup({});

  constructor(private _boardOrgPathStore: BoardOrgpathStore, private _modalService: ModalService, private _siteManagementService: SiteManagementService) {
    this.editable$ = this._siteManagementService.hasAuthority$('orgPath');
  }

  ngOnInit(): void {
    this.isSaved$ = combineLatest([this._boardOrgPathStore.selectIsSaved$, this._boardOrgPathStore.selectErrors$]).pipe(map(([isSaved, errors]) => isSaved || !!Object.keys(errors).length));
    this.hasErrors$ = this._boardOrgPathStore.selectErrors$.pipe(map((errors) => !!Object.keys(errors)?.length));

    // Manage switch view animation in detail
    this._boardOrgPathStore.selectedNode$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((newNode) => {
        if (newNode) {
          this.previousNode = this.activeNode;
          this.activeNode = newNode;
          this.index = (this.index + 1) % 2;
          this.activeDetailView = { id: this.index.toString() };
        } else {
          this.previousNode = this.activeNode = this.activeDetailView = undefined;
          this.index = 0;
        }
      });
  }

  getChangeDetails(): Observable<{ hasUnsavedChanges: boolean; hasInvalidChanges?: boolean }> {
    return this._boardOrgPathStore.selectErrors$.pipe(
      take(1),
      map((errors) => ({
        hasUnsavedChanges: this._boardOrgPathStore.hasUnsavedChanges(),
        hasInvalidChanges: !!Object.keys(errors).length,
      }))
    );
  }

  save(): Observable<boolean> {
    return this._boardOrgPathStore.saveDefinition();
  }

  getSchemaFromTagDesc(tags: Record<string, IEngineTagKeyDesc>): Record<string, IJsonSchema> {
    return Object.keys(tags).reduce((acc, curr) => {
      acc[curr] = tags[curr].schema as IJsonSchema;
      return acc;
    }, {} as Record<string, IJsonSchema>);
  }

  openEditTagPanel(): void {
    this.activeDetailView = { id: '2' };
  }

  getInvalidTag(): Observable<ITagChangeSummary[]> {
    return of([]);
  }

  addFirstNode(): void {
    this._boardOrgPathStore.addRootNode();
  }

  editTag(tag: { key: string; description: IEngineTagKeyDesc; operations: ITagOperationChange }): void {
    this._boardOrgPathStore.saveTag(tag);
  }

  goToCurrentNode(): void {
    this.activeDetailView = this.activeNode ? { id: this.index.toString() } : undefined;
  }

  openAddTagPanel(): void {
    const modalRef = this._modalService.open<OrgpathAddTagModalComponent, void>(
      OrgpathAddTagModalComponent,
      {
        showCloseIcon: true,
      },
      {
        width: '95vw',
        height: '95vh',
      }
    );

    modalRef.componentInstance.tagLevel = EngineTagLevel.BOARD;

    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed), takeUntil(this.componentDestroyed$)).subscribe((newTag) => {
      this._boardOrgPathStore.addNewTag(newTag);
    });
  }

  onSaveBoardOrgPathDef(): void {
    this._boardOrgPathStore.saveDefinition();
  }

  openPreview(open: boolean) {
    if (open) {
      this.activeDetailView = { id: '3' };
    } else {
      this.goToCurrentNode();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
