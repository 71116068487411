<div class="site-management-display">
  <div #displayComponent class="smd-container" *ngIf="display$ | async as display; else addDisplay">
    <div class="smd-header">
      <amp-screen-orientation
        [disabled]="isBoardLocked || !editable"
        [screenOrientation]="display.geometry.orientation"
        (screenOrientationChanged)="onScreenOrientationChange($event, display.id)"
        (mouseenter)="onShakeLock($event)"
        (mouseleave)="onShakeLock($event)"
      ></amp-screen-orientation>
      <div class="display-header">
        <avn-icon
          *ngIf="editable"
          icon="action:delete_forever"
          [fontSize]="16"
          [disabled]="isBoardLocked || !canRemoveDisplay(display)"
          (click)="removeDisplay(display.id)"
          (mouseenter)="onShakeLock($event)"
          (mouseleave)="onShakeLock($event)"
        ></avn-icon>
      </div>
    </div>
    <div class="smd-content">
      <div class="smdc-display">
        <div class="smdc-title">
          <avn-form-field>
            <avn-input
              [debounceTime]="300"
              [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DISPLAY_10' | transloco"
              [value]="display.name"
              [disabled]="isBoardLocked || !editable"
              (changed)="onChangeName($event, display.id)"
            ></avn-input>
          </avn-form-field>
        </div>

        <!-- Player connections -->
        <div class="smdc-player-loader">
          <avn-linear-loader [show]="primaryDeviceLoader$ | async"></avn-linear-loader>
          <ng-container *ngIf="primaryInput$ | async as primaryInput; else emptyPrimary">
            <ng-container
              *ngTemplateOutlet="existingPlayer; context: { device: primaryInput, deviceIdx: DisplayInputType.PRIMARY, label: 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PRIMARY_10' }"
            ></ng-container>
          </ng-container>
          <ng-template #emptyPrimary>
            <ng-container
              *ngTemplateOutlet="
                emptyPlayer;
                context: {
                  deviceIdx: DisplayInputType.PRIMARY,
                  label: editable ? 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.ADD_PRIMARY_10' : 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PRIMARY_NOT_SET_10'
                }
              "
            ></ng-container>
          </ng-template>
        </div>
        <div class="smdc-player-loader">
          <avn-linear-loader [show]="backupDeviceLoader$ | async"></avn-linear-loader>
          <ng-container *ngIf="backupInput$ | async as backupInput; else emptyBackup">
            <ng-container
              *ngTemplateOutlet="existingPlayer; context: { device: backupInput, deviceIdx: DisplayInputType.BACKUP, label: 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.SECONDARY_10' }"
            ></ng-container>
          </ng-container>
          <ng-template #emptyBackup>
            <ng-container
              *ngTemplateOutlet="
                emptyPlayer;
                context: {
                  deviceIdx: DisplayInputType.BACKUP,
                  label: editable ? 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.ADD_SECONDARY_10' : 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.SECONDARY_NOT_SET_10'
                }
              "
            ></ng-container>
          </ng-template>
        </div>
        <ng-template #existingPlayer let-device="device" let-deviceIdx="deviceIdx" let-label="label">
          <div
            class="smdc-player has-player"
            [class.active]="!!(activatedDeviceId$ | async) && !!device?.id && device.id === (activatedDeviceId$ | async)"
            [class.inactive]="!!(activatedDeviceId$ | async) && !!device?.id && device.id !== (activatedDeviceId$ | async)"
            [class.disabled]="isBoardLocked || !editable"
            [avnDragDropElementTarget]="dragDropTarget$ | async"
            [avnDragDropElementTargetShowOnDragOnly]="false"
            [avnDragDropElementTargetShowOnHoverOnly]="false"
            [avnDragDropElementTargetOpacity]="0.5"
            (elementDropped)="dropDevice($event, deviceIdx)"
            (click)="openDeviceSelector(deviceIdx)"
            [ngStyle]="{ 'border-color': 'var(--' + OperationalStateThemes[device?.deviceInfo.operationalState] + '-base)' }"
          >
            <div class="smdc-player-label">
              <span>{{ label | transloco }}</span>
              <avn-icon
                *ngIf="editable"
                [icon]="'action:delete_forever'"
                [fontSize]="12"
                (click)="deleteDevice(deviceIdx)"
                [disabled]="isBoardLocked"
                (mouseenter)="onShakeLock($event)"
                (mouseleave)="onShakeLock($event)"
              ></avn-icon>
            </div>
            <span class="smdc-player-hostname">{{ device?.deviceInfo.hostname || display.inputs[deviceIdx].deviceId }}</span>
            <span class="smdc-logical-player-id"> {{ ('siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.LOGICAL_PLAYER_20' | transloco) + ' : ' + display.inputs[deviceIdx].playerId }}</span>
          </div>
        </ng-template>

        <!-- Empty player connections -->
        <ng-template #emptyPlayer let-deviceIdx="deviceIdx" let-label="label">
          <div
            class="smdc-player smdc-add-player"
            [class.inactive]="!!(activatedDeviceId$ | async)"
            [class.disabled]="isBoardLocked || !editable"
            [avnDragDropElementTarget]="dragDropTarget$ | async"
            [avnDragDropElementTargetShowOnDragOnly]="false"
            [avnDragDropElementTargetShowOnHoverOnly]="false"
            [avnDragDropElementTargetOpacity]="0.5"
            (elementDropped)="dropDevice($event, deviceIdx)"
            (click)="openDeviceSelector(deviceIdx)"
          >
            <avn-icon *ngIf="editable" icon="content:add_circle_outline"></avn-icon>
            <span class="smdcp-text">{{ label | transloco }}</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #addDisplay>
  <div class="smd-container add-display" (click)="addNewDisplay()">
    <avn-icon icon="content:add_circle_outline"></avn-icon>
    <span>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.ADD_DISPLAY_10' | transloco }}</span>
  </div>
</ng-template>
