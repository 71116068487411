import {Injectable} from '@angular/core';
import {AuthFacade} from '@amp/auth';
import {SITE_MANAGEMENT_ROLES, SiteManagementRestrictedSection} from '../models/roles-permissions.constant';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteManagementService {

  private _hasAuthorities$: Observable<Record<SiteManagementRestrictedSection, boolean>>;

  constructor(private _authFacade: AuthFacade) {
    this._hasAuthorities$ = this._authFacade.authenticatedUserRoleNames$.pipe(
      map((userRoles: string[]) => {
        return Object.keys(SITE_MANAGEMENT_ROLES).reduce((acc, key) => {
          let hasRole = false;
          SITE_MANAGEMENT_ROLES[key].forEach((roleRequired) => {
            if (userRoles.includes(roleRequired)) {
              hasRole = true;
            }
          });
          return {
            ...acc,
            [key]: hasRole,
          };
        }, {} as Record<SiteManagementRestrictedSection, boolean>);
      })
    );
  }

  hasAuthority$(section: SiteManagementRestrictedSection): Observable<boolean> {
    return this._hasAuthorities$.pipe(
      map((authorities) => {
        return authorities[section];
      })
    )
  }
}
