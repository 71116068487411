import {CMRole} from '@amp/auth';

export type SiteManagementRestrictedSection = 'site' | 'tag' | 'orgPath' | 'appSettings';

/** Roles required to perform create/update/delete actions on each type of entity */
export const SITE_MANAGEMENT_ROLES: Record<SiteManagementRestrictedSection, CMRole[]> = {
  site: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN],
  tag: [CMRole.ROLE_ADMIN, CMRole.ROLE_NETWORK_ADMIN],
  orgPath: [CMRole.ROLE_ADMIN],
  appSettings: [CMRole.ROLE_ADMIN],
};
