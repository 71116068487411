<ng-container *transloco="let t; read: 'siteManagementScope.SITE_MANAGEMENT'">
  <avn-page *ngIf="selectedSitesList$ | async as selectedSitesList">
    <avn-ribbon
      [title]="t('DASHBOARD.CARDS.ALL_SITES.BREADCRUMB_TITLE_50')"
      [showAlternateContent]="selectedSitesList.length > 0"
      [fabIcons]="(isAdmin$ | async) ? 'action:settings' : null"
      (fabActionClicked)="openSettingsDrawer()"
    >
      <avn-breadcrumbs>
        <avn-breadcrumb [label]="t('DASHBOARD.RIBBON.TITLE.TITLE_50')" [routerLink]="['..']" queryParamsHandling="preserve"></avn-breadcrumb>
      </avn-breadcrumbs>
      <avn-ribbon-content>
        <div class="search-container">
          <avn-input
            [debounceTime]="300"
            [value]="siteListSearchTextFilter$ | async"
            [placeholder]="t('DASHBOARD.SITE_SEARCH.PLACEHOLDER_100')"
            (changed)="filterSitesBySearchText($event)"
          ></avn-input>
        </div>
      </avn-ribbon-content>

      <avn-ribbon-actions>
        <ng-container *ampHasAnyAuthority="rolesRequiredToEdit">
          <ng-container *ampHasFeature="'siteManagement.geodeticFixer'">
            <avn-ribbon-card
              *ngIf="geoProblematicSitesCount$ | async"
              icon="maps:edit_location_alt"
              [title]="t('DASHBOARD.RIBBON.ACTIONS.FIND_WARNINGS_10')"
              [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.FIND_WARNINGS_TOOLTIP_100')"
              [tooltipPlacement]="'left'"
              [count]="geoProblematicSitesCount$ | async"
              [countTheme]="'warning'"
              [disabled]="isLoadingGeoProblematicSites$ | async"
              (click)="redirectToGeoFixer()"
            >
            </avn-ribbon-card>
          </ng-container>
        </ng-container>
        <ng-container *ampHasAnyAuthority="rolesRequiredToEdit">
          <avn-ribbon-card
            icon="content:add"
            [title]="t('DASHBOARD.RIBBON.ACTIONS.ADD_SITE_50')"
            (actioned)="onCreateSite()">
          </avn-ribbon-card>
        </ng-container>
        <avn-ribbon-card
          icon="action:fact_check"
          [title]="t('DASHBOARD.RIBBON.ACTIONS.SELECT_SITES_50')"
          (actioned)="onSelectSites()">
        </avn-ribbon-card>
        <ng-container *ampHasAnyAuthority="rolesRequiredToEdit">
          <ng-container *ampHasFeature="'siteManagement.siteCSVSync'">
            <avn-ribbon-card
              [icon]="(hasSyncJobInProgress$ | async) ? 'notification:sync_lock' : 'notification:sync'"
              [title]="t('DASHBOARD.RIBBON.ACTIONS.SYNC_SITES_50')"
              (actioned)="openSiteSyncModal(selectedSitesList)"
              [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.SYNC_SITES_DISABLED_TOOLTIP_50')"
              [tooltipPlacement]="'bottom'"
              [tooltipDisabled]="(hasSyncJobInProgress$ | async) === false"
              [disabled]="hasSyncJobInProgress$ | async"
            >
            </avn-ribbon-card>
          </ng-container>
        </ng-container>
      </avn-ribbon-actions>

      <avn-ribbon-alternate-content [title]="t('DASHBOARD.RIBBON.SELECTION_CONTEXT.COUNT_10', { val: selectedSitesList.length })">
        <avn-ribbon-card
          *ngIf="(editable$ | async) && selectedSitesList.length > 0"
          icon="content:delete_sweep"
          [title]="t('DASHBOARD.RIBBON.ACTIONS.REMOVE_SITE_50')"
          (actioned)="onDeletedSites(selectedSitesList)"
        >
        </avn-ribbon-card>
        <avn-ribbon-card
          *ngIf="editable$ | async"
          icon="file:file_download"
          [title]="t('DASHBOARD.RIBBON.ACTIONS.EXPORT_SITES_50')"
          [disabled]="!selectedSitesList.length"
          (actioned)="onExportSites(selectedSitesList)">
        </avn-ribbon-card>
      </avn-ribbon-alternate-content>
    </avn-ribbon>

    <avn-main avnScroll>
      <avn-master-detail [activeDetailView]="activeDetail">
        <ng-template avnDetailView="siteDetail">
          <div class="site-detail-side-menu-container">
            <div class="header">
              <avn-breadcrumbs *ngIf="breadcrumbs$ | async as breadcrumbs">
                <avn-breadcrumb
                  *ngFor="let breadcrumb of breadcrumbs"
                  [routerLink]="breadcrumb.path"
                  queryParamsHandling="preserve"
                  [label]="breadcrumb.label"
                  [icon]="breadcrumb.icon"
                ></avn-breadcrumb>
              </avn-breadcrumbs>
              <avn-icon [icon]="'action:close'" (click)="onCloseSiteDetail()"></avn-icon>
            </div>
            <div class="content" avnScroll>
              <router-outlet></router-outlet>
            </div>
          </div>
        </ng-template>

        <avn-data-table
          #dataTableComponent
          avnMasterView
          [columns]="columns$ | async"
          [dataSource]="dataSource$ | async"
          [dataSourceConfig]="{ id: 'id' }"
          [minColumnWidthPx]="(editable$ | async) ? 120 : 80"
          [rowHeight]="80"
          [keyboardNavigationEnabled]="true"
          [headerTooltipsEnabled]="true"
          [infiniteScrollEnabled]="false"
          [forceLoadingState]="isSiteListLoading$ | async"
          [selectionMode]="'multiple'"
          [selectedItemIds]="(selectedSiteIds$ | async) || []"
          [serverSorting]="false"
          [footerHeight]="50"
          [footerTemplate]="footerTemplate"
          [sortBy]="siteListSort$ | async"
          [themeIndicatorWidthPx]="3"
          (selectionChanged)="onSelectionChanged($event.selected)"
          (columnSorted)="updateSiteListSort($event)"
          (rowActivated)="onRowActived($event.activeRow?.data)"
        >
          <avn-empty-data-message [icon]="'communication:location_off'" [text]="t('DASHBOARD.SITE_SEARCH.NO_SITE_FOUND.TEXT_50')" [subText]="t('DASHBOARD.SITE_SEARCH.NO_SITE_FOUND.SUBTEXT_100')">
          </avn-empty-data-message>
        </avn-data-table>

        <ng-template #footerTemplate let-totalRowCount="totalRowCount">
          <div class="site-list-footer">
            {{ t('DASHBOARD.FOOTER.SITE_COUNT', { count: totalRowCount }) }}
          </div>
        </ng-template>

        <ng-template #siteListActions let-row="row">
          <avn-data-table-actions [dataRow]="row">
            <avn-icon
              *ampHasAnyAuthority="rolesRequiredToEdit"
              icon="action:delete"
              [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.EDIT_SITE_50', { action: t('GLOBAL.DELETE_10') })"
              tooltipPlacement="top"
              (click)="onDeletedSites(selectedSitesList, row)"
            >
            </avn-icon>
            <avn-icon
              icon="action:open_in_new"
              [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.OPEN_IN_NEW_50')"
              tooltipPlacement="top"
              (click)="openNewTab(row.id)">
            </avn-icon>
            <avn-icon icon="content:web_stories" [avnTooltip]="t('DASHBOARD.RIBBON.ACTIONS.VIEW_BOARD_50')" tooltipPlacement="top" (click)="redirectToBoardEditor(row.id)"> </avn-icon>
          </avn-data-table-actions>
        </ng-template>
      </avn-master-detail>
    </avn-main>
  </avn-page>

  <amp-site-templates></amp-site-templates>
</ng-container>
