<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.ORGPATH_EDITOR.CREATE_NEW_TAG_50' | transloco"
    (closeClicked)="onClose()"
  ></avn-dialog-header>
  <avn-dialog-content>
    <amp-create-tag-key [tagLevel]="tagLevel" [restrictTypes]="true" [enableLevelChange]="false" (actioned)="onSave($event)"></amp-create-tag-key>
  </avn-dialog-content>
</avn-dialog-layout>
