import { IModalComponent, IModalConfig, IOptionData, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { TagValueCreationConfig } from '../../model/tag-value-creation-config.interface';
import { PropertyType } from '../../model/operation-scope.interface';
import { FormControl, Validators } from '@angular/forms';
import { IJsonSchema } from '@activia/json-schema-forms';
import { IEngineTagKeyDesc } from '../../model/engine-tag-key.interface';
import { IEngineTagValue } from '../../model/tag-value.interface';

@Component({
  selector: 'amp-tag-value-creation-modal',
  templateUrl: './tag-value-creation-modal.component.html',
  styleUrls: ['./tag-value-creation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagValueCreationModalComponent implements IModalComponent, OnInit, OnDestroy {
  /** List of tags to be created when user confirm */
  @Output() actioned = new EventEmitter<IEngineTagValue>();

  /** The list of tag keys for dropdown */
  tagKeysOptionList$: Observable<IOptionData<IEngineTagKeyDesc>[]>;

  /** Current key being selected */
  currentKey$ = new BehaviorSubject<{ key: string; propertyType: PropertyType; description: IEngineTagKeyDesc }>({
    key: '',
    propertyType: undefined,
    description: undefined,
  });

  /** Schema, formGroup and properties to use JsonSchemaComponent  */
  currentSchema$: Observable<IJsonSchema> = new Subject();

  /** Check if the current tag is a json-schema object */
  isJsonSchema$: Observable<boolean>;

  /** Form control for json-schema-control component */
  jsonSchemaControl = new FormControl(undefined, Validators.required);

  private _componentDestroyed$ = new Subject<void>();

  constructor(private _dialogRef: ModalRef<TagValueCreationModalComponent>, @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<TagValueCreationConfig>) {
    this.tagKeysOptionList$ = dialogConfig.data.keyOptions$;
  }

  public ngOnInit(): void {
    this.currentSchema$ = this.currentKey$.pipe(map(({ description }) => description?.schema as IJsonSchema));

    this.isJsonSchema$ = this.currentKey$.pipe(map(({ key }) => !!key?.endsWith('.json')));

    this.currentSchema$
      .pipe(
        filter((schema) => !!schema),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((schema) => this.jsonSchemaControl.reset(schema?.default));
  }

  public onSave() {
    if (this.jsonSchemaControl.valid) {
      this.currentKey$.pipe(take(1)).subscribe(({ key, propertyType, description }) => {
        this.actioned.emit({ key, values: this.jsonSchemaControl.value, propertyType, keyDescription: description });
        this.onClose();
      });
    }
  }

  public onKeyChanged(option: IOptionData<IEngineTagKeyDesc>) {
    this.currentKey$.next(
      !!option
        ? {
            key: option.value || option,
            propertyType: option.group as PropertyType,
            description: option.data,
          }
        : { key: '', propertyType: undefined, description: undefined }
    );
  }

  public canClose(): boolean {
    return false;
  }

  public onClose() {
    this._dialogRef.close();
  }

  public ngOnDestroy() {
    this.currentKey$.complete();
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  public getOwnerProperty({ description }): string {
    return (description as IEngineTagKeyDesc)?.owner ? description?.owner : '';
  }
}
