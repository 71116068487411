import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { BoardDTO, BoardTagsService, DeviceService, MonitoringService, SiteDTO } from '@activia/cm-api';
import { combineLatest, EMPTY, forkJoin, Observable, of, ReplaySubject, share } from 'rxjs';
import { catchError, defaultIfEmpty, filter, first, map, mapTo, mergeAll, mergeMap, retry, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AsyncData, AsyncDataState, dataOnceReady, dataWhenReady, hasAsyncDataError, LoadingState } from '@activia/ngx-components';
import { ICombinedDeviceInfo, IDeviceInfo, IDeviceToDisplayConnection, ISiteStructure } from './site-monitoring-detail.model';
import { getSiteDisplayStructure, mergeDeviceAndPlayerInfo, toOptimisticMonitoringData } from './site-monitoring-detail.utils';
import { getBoardsDeviceConnectivity, getBoardsDeviceIds } from '../../../utils/site-boards.utils';
import { AlarmEventLevel, HealthStatusCode, toDeviceMonitoringData } from '@amp/devices';
import { LiveDeviceInfoDTO, PlayerService } from '@activia/device-screenshot-api';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { DateTime } from 'luxon';
import { ISiteMonitoringData } from '../../../model/site-monitoring-data.interface';
import { toInternalSiteMonitoredValues } from '../../../utils/site-monitored-values.utils';
import { getTagsStructureFromBoard, ITagStructure, selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState, selectBoardTagKeysSchema } from '@amp/tag-operation';
import { Store } from '@ngrx/store';
import { IBoardWithOrgPath } from '../../../model/board-with-orgpath.interface';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Shared local state for the site monitoring detail component and its sub components
 * **/
export interface IBoardsInfo {
  boards: IBoardWithOrgPath[];
  deviceIds: number[]; // ids of devices found across all boards
  connectivity: IDeviceToDisplayConnection[]; // connectivity between screens and devices accross all boards of the site
}

export interface SiteMonitoringDetailState {
  site: SiteDTO;
  monitoredData: AsyncData<ISiteMonitoringData>; // Monitored data of this site
  structure: AsyncData<ISiteStructure>;
  boardsInfo: AsyncData<IBoardsInfo>;
  alarmsDataState: AsyncDataState; // loading state of site alarms
  devices: AsyncData<IDeviceInfo[]>; // device + logical players info per device (retrieved in one shot from CM)
  liveInfo: Record<number, AsyncData<LiveDeviceInfoDTO>>; // live info about the device like alarms and logical players (retrieved separately for each device using player API)
}

/**
 * Store used by the site monitoring detail component.
 * A unique instance of this store will be shared by all sub components
 */
@Injectable()
export class SiteMonitoringDetailStore extends ComponentStore<SiteMonitoringDetailState> {
  // cache to avoid recalculation of device combined data
  private _deviceInfoCache: Record<
    number,
    {
      data$: Observable<ICombinedDeviceInfo>;
      state$: Observable<AsyncDataState>;
    }
  > = {};

  /** Selectors */
  site$ = this.select((state) => state.site);
  monitoredData$ = this.select((state) => state.monitoredData.data);
  monitoredDataState$ = this.select((state) => state.monitoredData.state);
  structureData$ = this.select((state) => state.structure.data);
  structureDataState$ = this.select((state) => state.structure.state);
  boardIds$ = this.select(this.structureData$, (structure) => Object.keys(structure?.boards || {}).map((boardId) => +boardId));
  boardsInfoData$ = this.select((state) => state.boardsInfo.data);
  boardsInfoDataState$ = this.select((state) => state.boardsInfo.state);
  devicesData$ = this.select((state) => state.devices.data);
  devicesDataState$ = this.select((state) => state.devices.state);
  alarmsDataState$ = this.select((state) => state.alarmsDataState);

  /** Reducers **/
  setSite = this.updater((state: SiteMonitoringDetailState, site: SiteDTO) => ({
    ...state,
    site,
  }));

  setMonitoredData = this.updater((state: SiteMonitoringDetailState, monitoredData: ISiteMonitoringData) => ({
    ...state,
    monitoredData: {
      ...state.monitoredData,
      data: monitoredData,
    },
  }));

  setMonitoredDataState = this.updater((state: SiteMonitoringDetailState, asyncDataState: AsyncDataState) => ({
    ...state,
    monitoredData: {
      ...state.monitoredData,
      state: asyncDataState,
    },
  }));

  setStructureDataState = this.updater((state: SiteMonitoringDetailState, asyncDataState: AsyncDataState) => ({
    ...state,
    structure: {
      ...state.structure,
      state: asyncDataState,
    },
  }));

  setBoardsInfo = this.updater((state: SiteMonitoringDetailState, boardsInfo: IBoardsInfo) => ({
    ...state,
    boardsInfo: {
      ...state.boardsInfo,
      data: boardsInfo,
    },
  }));

  setBoardsInfoDataState = this.updater((state: SiteMonitoringDetailState, asyncDataState: AsyncDataState) => ({
    ...state,
    boardsInfo: {
      ...state.boardsInfo,
      state: asyncDataState,
    },
  }));

  setDevices = this.updater((state: SiteMonitoringDetailState, devices: AsyncData<IDeviceInfo[]>) => ({
    ...state,
    devices,
  }));

  setDevicesDataState = this.updater((state: SiteMonitoringDetailState, asyncDataState: AsyncDataState) => ({
    ...state,
    devices: {
      ...state.devices,
      state: asyncDataState,
    },
  }));

  setAlarmsDataState = this.updater((state: SiteMonitoringDetailState, alarmsDataState: AsyncDataState) => ({
    ...state,
    alarmsDataState,
  }));

  resetPlayers = this.updater((state: SiteMonitoringDetailState) => ({
    ...state,
    liveInfo: {},
  }));

  setDeviceLiveInfoDataState = this.updater(
    (
      state: SiteMonitoringDetailState,
      payload: {
        deviceId: number;
        asyncDataState: AsyncDataState;
        lastSuccess?: Date;
        lastAttempt?: Date;
      }
    ) => ({
      ...state,
      liveInfo: {
        ...state.liveInfo,
        [payload.deviceId]: {
          ...(state.liveInfo[payload.deviceId] || { data: null }),
          state: payload.asyncDataState,
          ...(payload.lastAttempt ? { lastAttempt: payload.lastAttempt } : {}),
          ...(payload.lastSuccess ? { lastSuccess: payload.lastSuccess } : {}),
        },
      },
    })
  );

  setDeviceLiveInfo = this.updater(
    (
      state: SiteMonitoringDetailState,
      payload: {
        deviceId: number;
        data: LiveDeviceInfoDTO;
      }
    ) => ({
      ...state,
      liveInfo: {
        ...state.liveInfo,
        [payload.deviceId]: {
          ...state.liveInfo[payload.deviceId],
          data: payload.data,
        },
      },
    })
  );

  // EFFECTS
  fetchMonitoredData = this.effect(() =>
    combineLatest([
      this.site$.pipe(map((s) => s?.id)),
      this._siteMonitoringFacade.keyMetricsDataSource$.pipe(map((km) => km.monitoringValues)),
      dataOnceReady(this._siteMonitoringFacade.userPreferences$, this._siteMonitoringFacade.userPreferencesDataState$).pipe(map((userPref) => userPref.enableSiteDetailKeyMetrics)),
    ]).pipe(
      withLatestFrom(dataOnceReady(this.monitoredData$, this.monitoredDataState$)),
      // For now this is used for key metrics only, fetch monitored data only when key metrics widget is enabled
      filter(([[_, __, enableSiteDetailKeyMetrics]]) => enableSiteDetailKeyMetrics),
      switchMap(([[siteId, monitoredValuesToFetch, _], monitoredData]) => {
        if (!!siteId) {
          this.setMonitoredDataState(LoadingState.LOADING);
        }
        let monitoredData$;
        if (monitoredData && Object.keys(monitoredData).length > 0) {
          monitoredData$ = of(monitoredData);
        } else {
          monitoredData$ = !siteId ? of({}) : this._siteMonitoringFacade.fetchOrganizationSummary(siteId, monitoredValuesToFetch);
        }
        return monitoredData$.pipe(
          tapResponse(
            (resp) => {
              // parse the structure from the boards returned
              const monitoredDataResp = toInternalSiteMonitoredValues(resp);
              this.setMonitoredData(monitoredDataResp);
              this.setMonitoredDataState(LoadingState.LOADED);
            },
            (err: unknown) => this.setMonitoredDataState({ errorMsg: err as string })
          )
        );
      })
    )
  );

  /** Automatically called when the site changes **/
  fetchSiteBoards = this.effect(() =>
    this.site$.pipe(
      filter((site) => !!site),
      switchMap((site) => {
        if (!!site) {
          this.setBoardsInfoDataState(LoadingState.LOADING);
        }
        const boards$: Observable<BoardDTO[]> = !site ? of([]) : this._siteMonitoringFacade.fetchBoardsBySiteId(site.id);
        return boards$.pipe(
          switchMap((boards) =>
            forkJoin(
              boards.map((board) =>
                combineLatest([
                  this._boardTagsService.findTagsForEntity(board.id),
                  dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState)),
                  dataOnceReady(this._store.select(selectBoardTagKeysSchema), this._store.select(selectBoardOrgPathDefinitionState)),
                ]).pipe(
                  first(),
                  map(([tags, boardOrgPathDef, tagsDefinitions]) => {
                    // Sanitize tags to be only have 1 value
                    const tagValues = Object.keys(tags).reduce((acc, curr) => ({ ...acc, [curr]: tags[curr][0] }), {});
                    const tagsStructure = getTagsStructureFromBoard(boardOrgPathDef, tagValues, tagsDefinitions);
                    return {
                      ...board,
                      organizationPath: [...tagsStructure.map((e) => e.value), board.name].join('.'),
                      tagValues: tagsStructure,
                    } as IBoardWithOrgPath;
                  })
                )
              )
            ).pipe(defaultIfEmpty([]))
          ),
          tapResponse(
            (boards) => {
              this.setStructureDataState(LoadingState.LOADING);
              // parse the structure from the boards returned
              const structure = getSiteDisplayStructure(site, boards);
              this.patchState({ structure: { data: structure, state: LoadingState.LOADED } });

              // get the connectivity between devices and display
              const connectivity = getBoardsDeviceConnectivity(boards);
              const deviceIds = getBoardsDeviceIds(boards);
              const boardsInfo = { boards, connectivity, deviceIds };
              this.setBoardsInfo(boardsInfo);
              this.setBoardsInfoDataState(LoadingState.LOADED);
              // fetch the devices from CM and contact the devices via CGI separately
              this.fetchBoardsDevices(boardsInfo);
              this.fetchDevicesLiveInfo(deviceIds);
            },
            (err: unknown) => this.setBoardsInfoDataState({ errorMsg: err as string })
          )
        );
      })
    )
  );

  // EFFECTS
  fetchBoardsDevices = this.effect((boardsInfo$: Observable<IBoardsInfo>) =>
    boardsInfo$.pipe(
      switchMap(({ connectivity, deviceIds }) => {
        // get ids of all devices across all boards
        // do nothing we dont have any device
        if (deviceIds.length === 0) {
          this.setDevices({
            data: [],
            state: LoadingState.LOADED,
          });
          return EMPTY;
        }
        this.setDevicesDataState(LoadingState.LOADING);
        this.setAlarmsDataState(LoadingState.LOADING);

        const nextFireTimeForDevices$ = forkJoin(
          deviceIds.map((deviceId) =>
            this._monitoringService.setNextFireTimeDevice(deviceId, { time: DateTime.now().toUTC().toISO() }).pipe(
              mapTo(true),
              catchError(() => of(false))
            )
          )
        );

        // first request a monitoring data update for all the devices of the board
        // then fetch all devices + their monitoring data (it still may not content the latest data tho, we cannot know)
        return nextFireTimeForDevices$.pipe(
          switchMap(() => this._siteMonitoringFacade.preference$), // switch to react to preferences changes
          withLatestFrom(this._siteMonitoringFacade.userPreferences$.pipe(map((userPref) => userPref.showOnlyAlarmErrors))),
          switchMap(([moduleSettings, showOnlyAlarmErrors]) =>
            forkJoin([
              this._deviceService.getDeviceByIds(deviceIds),
              this._deviceService.getMonitoringDataByDeviceIds(deviceIds),
              // TODO: active value should optional
              this._siteMonitoringFacade.fetchAlarmEvents(this.get().site.id, showOnlyAlarmErrors ? AlarmEventLevel.Error : AlarmEventLevel.Debug, true).pipe(
                tap(() => this.setAlarmsDataState(LoadingState.LOADED)),
                catchError((err) => {
                  this.setAlarmsDataState({ errorMsg: err as any });
                  return of([]);
                })
              ),
            ]).pipe(
              tapResponse(
                ([devicesData, devicesMonitoringData, alarms]) => {
                  // convert into internal structure
                  const devicesInfo: IDeviceInfo[] = deviceIds.map((deviceId) => {
                    const device = devicesData.devices.find((d) => d?.id === deviceId);
                    // if the device is not found just return null
                    if (!device) {
                      return null;
                    }

                    const deviceData = devicesMonitoringData?.dataset?.find((data) => data.id === deviceId);
                    let deviceMonitoringData = toDeviceMonitoringData(deviceData);
                    if (moduleSettings.defaultToOptimisticView) {
                      deviceMonitoringData = toOptimisticMonitoringData(deviceMonitoringData);
                    }

                    // get all device to display connections
                    const deviceConnectivity = connectivity.filter((c) => c.deviceId === deviceId);
                    return {
                      device,
                      monitoringData: deviceMonitoringData,
                      connectivity: deviceConnectivity,
                      lastUpdate: deviceData.lastUpdate,
                      alarms: alarms.filter((alarm) => alarm.deviceId === device.id),
                    } as IDeviceInfo;
                  });

                  this.setDevices({
                    data: devicesInfo,
                    state: LoadingState.LOADED,
                  });
                },
                (err: unknown) => this.setDevicesDataState({ errorMsg: err as string })
              )
            )
          )
        );
      })
    )
  );

  // EFFECTS
  fetchAlarms = this.effect((devices$: Observable<Array<IDeviceInfo>>) =>
    devices$.pipe(
      withLatestFrom(this._siteMonitoringFacade.userPreferences$.pipe(map((userPref) => userPref.showOnlyAlarmErrors))),
      switchMap(([devices, showOnlyAlarmErrors]) => {
        this.setAlarmsDataState(LoadingState.LOADING);
        return this._siteMonitoringFacade.fetchAlarmEvents(this.get().site.id, showOnlyAlarmErrors ? AlarmEventLevel.Error : AlarmEventLevel.Debug, true).pipe(
          tapResponse(
            (alarms) => {
              // Replace alarms in each device
              const updatedDevices = devices.map((device) => ({
                ...device,
                alarms: alarms.filter((alarm) => alarm.deviceId === device.device.id),
              }));

              this.setDevices({
                data: updatedDevices,
                state: LoadingState.LOADED,
              });
              this.setAlarmsDataState(LoadingState.LOADED);
            },
            (err: unknown) => this.setAlarmsDataState({ errorMsg: err as any })
          )
        );
      })
    )
  );

  fetchDevicesLiveInfo = this.effect((deviceIds$: Observable<number[]>) => {
    const attemptDate = new Date();

    return deviceIds$.pipe(
      switchMap((deviceIds) => {
        // reset the players data when there are no devices
        if (deviceIds.length === 0) {
          this.resetPlayers();
          return EMPTY;
        }
        return of(deviceIds);
      }),
      mergeAll(), // transform the array into a stream of multiple items
      mergeMap((deviceId) => {
        this.setDeviceLiveInfoDataState({ deviceId, asyncDataState: LoadingState.LOADING });
        return this._playerService.getLiveDeviceInfo(deviceId).pipe(
          retry(2),
          tapResponse(
            (liveDeviceInfo) => {
              this.setDeviceLiveInfo({ deviceId, data: liveDeviceInfo });
              this.setDeviceLiveInfoDataState({
                deviceId,
                asyncDataState: LoadingState.LOADED,
                lastAttempt: attemptDate,
                lastSuccess: attemptDate,
              });
            },
            (err: unknown) =>
              this.setDeviceLiveInfoDataState({
                deviceId,
                asyncDataState: { errorMsg: err as string },
                lastAttempt: attemptDate,
              })
          )
        );
      })
    );
  });

  /** Refreshes the device and players data for the current boards **/
  refreshDevices() {
    this.fetchBoardsDevices(this.get().boardsInfo.data);
    this.fetchDevicesLiveInfo(this.get().boardsInfo.data.deviceIds);
  }

  refreshAlarms() {
    this.fetchAlarms(this.get().devices.data);
  }

  /** Returns the full name of the board including zones and sections */
  getBoardFullName(boardId: number, includeBoardName = false): string[] {
    const fullName = this.get().structure?.data?.boards[boardId]?.fullName;
    if (!fullName) {
      return [];
    }
    // remove dummy section names created by the structure
    return includeBoardName ? fullName : fullName.slice(0, fullName.length - 1);
  }

  getSections(orgPath: string, boardName: string): ITagStructure[] {
    return this.get()
      .structure.data.sections.find((section) => orgPath.startsWith(section.name))
      ?.tagsStructure?.concat({
        value: boardName,
        title: this._translateService.translate('siteMonitoringSharedScope.SITE_MONITORING.GLOBAL.NAME_15'),
      } as ITagStructure);
  }

  /** Returns the boards in order for the specified ids **/
  selectBoards(boardIds: number[]): Observable<IBoardWithOrgPath[]> {
    const boards$ = this.select((state) => state.boardsInfo.data.boards);
    return this.select(boards$, (boards) => (boards || []).filter((board) => boardIds?.includes(board.id)).sort((a, b) => a.order - b.order));
  }

  /**
   * Selects the information about the devices
   * Use caching as the device info for a same device can be used across many boards and we dont want o recalculate it everytime
   * Combines the data from CM and the device cgi endpoint
   */
  selectDeviceInfo(deviceId: number): { data$: Observable<ICombinedDeviceInfo>; state$: Observable<AsyncDataState> } {
    if (this._deviceInfoCache[deviceId]) {
      return this._deviceInfoCache[deviceId];
    }

    const liveInfo$ = this.select((state) => state.liveInfo[deviceId]);
    const deviceDataState$ = this.selectDeviceDataState(deviceId);
    const deviceDataReady$ = dataWhenReady(this.devicesData$, deviceDataState$).pipe(map((devices) => devices.find((d) => d?.device.id === deviceId)));
    const deviceInfo$ = combineLatest([deviceDataReady$, liveInfo$]).pipe(
      map(([deviceInfo, liveInfo]) => {
        const lastPlayerAttemptFailed = liveInfo.lastAttempt !== liveInfo.lastSuccess;
        return deviceInfo ? mergeDeviceAndPlayerInfo(deviceInfo, liveInfo.data, liveInfo.state, lastPlayerAttemptFailed) : null;
      })
    );
    this._deviceInfoCache[deviceId] = {
      data$: deviceInfo$.pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: false,
          resetOnComplete: false,
          resetOnError: false,
        })
      ),
      state$: deviceDataState$.pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: false,
          resetOnComplete: false,
          resetOnError: false,
        })
      ),
    };
    return this._deviceInfoCache[deviceId];
  }

  /**
   * Selects the  loading state of the data needed for device
   * If a device is currently reported as unreachable by CM, we will mark this device as loaded right away but still try to contact it in the background
   * Otherwise we will mark this device as loaded when the cgi call to the device has completed
   */
  /**
   * Selects the  loading state of the data needed for device
   * If a device is currently reported as unreachable by CM, we will mark this device as loaded right away but still try to contact it in the background
   * Otherwise we will mark this device as loaded when the cgi call to the device has completed
   */
  selectDeviceDataState(deviceId: number): Observable<AsyncDataState> {
    return this.devicesDataState$.pipe(
      switchMap((devicesDataState) => {
        if (devicesDataState === LoadingState.LOADED) {
          return this.devicesData$.pipe(
            switchMap((devices) => {
              const device = devices.find((d) => d?.device.id === deviceId);
              if (!device || device.monitoringData.HEALTH_STATUS === HealthStatusCode.UNREACHABLE) {
                return of(devicesDataState);
              }
              // get the data state for the current device
              const playerDataStateCallCompleted$ = this.select((state) => state.liveInfo[deviceId]).pipe(
                map((liveInfo: AsyncData<LiveDeviceInfoDTO>) => liveInfo.state === LoadingState.LOADED || hasAsyncDataError(liveInfo.state)),
                filter((loaded) => !!loaded)
              );
              // we still want the device state, but only once we reached the player
              return playerDataStateCallCompleted$.pipe(map(() => devicesDataState));
            })
          );
        }
        return of(devicesDataState);
      })
    );
  }

  selectDevicesInfo(deviceIds: number[]): Observable<ICombinedDeviceInfo[]> {
    return combineLatest(deviceIds.map((deviceId) => this.selectDeviceInfo(deviceId).data$));
  }

  constructor(
    private _deviceService: DeviceService,
    private _playerService: PlayerService,
    private _monitoringService: MonitoringService,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    private _boardTagsService: BoardTagsService,
    private _translateService: TranslocoService,
    private _store: Store
  ) {
    // initial state
    super({
      site: null,
      monitoredData: {
        data: {},
        state: LoadingState.INIT,
      },
      structure: {
        data: null,
        state: LoadingState.INIT,
      },
      boardsInfo: {
        data: {
          boards: [],
          deviceIds: [],
          connectivity: [],
        },
        state: LoadingState.INIT,
      },
      devices: {
        data: [],
        state: LoadingState.INIT,
      },
      liveInfo: {},
      alarmsDataState: LoadingState.INIT,
    });
  }
}
