import { IJsonSchema } from '@activia/json-schema-forms';
import { IOrgPathNode, IOrgPathNodeErrors, OrgpathState } from './orgpath.interface';
import { IOrgPathDefNode } from '@amp/tag-operation';
import { TagKeyDescDTO } from '@activia/cm-api';
import { v4 as uuidV4 } from 'uuid';
import { ComponentStore } from '@ngrx/component-store';

export abstract class OrgpathStoreBase extends ComponentStore<OrgpathState> {
  /** Sanitize board org path tree to link children with parent and add ID to node */
  linkChildToParent(currentNode: IOrgPathNode, nodeEntities?: Record<string, IOrgPathNode>): Record<string, IOrgPathNode> {
    if (!nodeEntities) {
      nodeEntities = {};
    }
    if (currentNode) {
      currentNode.id = uuidV4(); // Add id to the node
      nodeEntities[currentNode.id] = currentNode;

      if (currentNode?.childOneOf?.length) {
        for (const childNode of currentNode.childOneOf) {
          this.linkChildToParent(childNode, nodeEntities);
          childNode.parent = currentNode;
        }
      }
    }

    return nodeEntities;
  }

  getAllChildren(node: IOrgPathNode, ids?: string[]): string[] {
    if (!ids) {
      ids = []; // Start with empty list
    }

    ids.push(node.id);

    // Get all children
    if (node?.childOneOf?.length) {
      for (const childNode of node.childOneOf) {
        this.getAllChildren(childNode, ids);
      }
    }

    return ids;
  }

  /** Sanitize the tree to remove useless attribute and break recursive reference */
  sanitizeNode(node: IOrgPathNode): IOrgPathDefNode {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parent, id, ...sanitizedNode } = { ...node }; // Remove id and parent attribute

    return {
      ...sanitizedNode,
      childOneOf: sanitizedNode.childOneOf?.map((childNode) => this.sanitizeNode(childNode)),
    };
  }

  /** Get all errors in the tree */
  getErrorsInNodes(nodeEntities: Record<string, IOrgPathNode>, tagsDefinitions: Record<string, TagKeyDescDTO>): Record<string, IOrgPathNodeErrors> {
    return Object.keys(nodeEntities).reduce((errors, nodeId) => {
      const currentNode = nodeEntities[nodeId];

      // Check condition when node is 'tag'
      if (currentNode.tag) {
        this.getTagError(currentNode, errors, nodeId, tagsDefinitions);
      }
      // Check condition when node is 'name'
      else if (currentNode.property) {
        // Check if node is property name, then no children
        if (currentNode.childOneOf?.length && currentNode.property === 'name') {
          errors[nodeId] = { ...errors[nodeId], notALeaf: true };
        }
      } else {
        errors[nodeId] = { ...errors[nodeId], noType: true };
      }

      return errors;
    }, {} as Record<string, IOrgPathNodeErrors>);
  }

  /**
   * Checks for errors in the given organization path node when node is of type tag.
   */
  private getTagError(currentNode: IOrgPathNode, errors: Record<string, IOrgPathNodeErrors>, nodeId: string, tagsDefinitions: Record<string, TagKeyDescDTO>) {
    if (currentNode.childOneOf?.length) {
      // Check if there is no duplicate condition in children
      const conditionList = currentNode.childOneOf.map((e) => e.dependentItem);
      const duplicate = conditionList.filter((e, i, a) => a.indexOf(e) !== i);
      if (duplicate.length) {
        errors[nodeId] = { ...errors[nodeId], hasDuplicate: duplicate };
      }

      // Check if there is no missing condition when tag is an enum
      const enumList = (tagsDefinitions[currentNode.tag]?.schema as IJsonSchema)?.enum;
      if (enumList?.length && !(conditionList.some((e) => !e) || enumList.every((e) => conditionList.includes(e)))) {
        errors[nodeId] = { ...errors[nodeId], missingConditions: enumList.filter((e) => !conditionList.includes(e)) };
      }
    } else {
      // Error when tag node has no child
      errors[nodeId] = { ...errors[nodeId], noChild: true };
    }
  }

  /**
   *  Clear dependentItem conditions
   */
  clearDependentItemsFromChildren(selectedNode: IOrgPathNode) {
    const childOneOf = selectedNode?.childOneOf;

    if (childOneOf) {
      for (const child of childOneOf) {
        delete child.dependentItem;
      }
    }
  }
}
