import { DeviceService } from '@activia/cm-api';
import { enumToMap, INlpDatasourceService, ITokenSuggestion, NlpExpressionParser } from '@activia/ngx-components';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TokenType } from 'chevrotain';
import { iif, Observable, of, zip } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { getI18nFormattedValue } from '@amp/utils/common';

import { HealthStatusCode } from './../../model/health-status.enum';
import {
  EncMode,
  EncType,
  Hostname,
  IpAddress,
  MvFtpSvc,
  MvHealthStatus,
  MvHttpSvc,
  MvOmnicastSvc,
  MvPlayerCs,
  MvPlayerSt,
  MvPlayerSvc,
  NlpOperationalState,
  NumericValue,
  StringValue,
  TagField,
  TypeBrand,
  TypeModel,
  TypeSeries,
} from './device-filter.tokens';
import { ServiceStatusCode } from '../../model/service-status.enum';
import { OperationalState } from '../../model/operational-state.enum';
import { DateTime } from 'luxon';
import { EnclosureMode, EnclosureType } from '../../model/enclosure.enum';

/***
 * This service contains the multiple datasources for the NLP device filter autocomplete
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceFilterNlpDatasourceService implements INlpDatasourceService {
  /** @ignore internal cache to avoid fetching non-changing data several times */
  private _cache = new Map<TokenType, string[]>();

  /** @ignore time to live in Ms for each of the caches. When not specified for a token, it means no expiry */
  private readonly _cacheTTL = new Map<TokenType, number>([
    [TagField, 1000 * 60 * 1], // 1 min
  ]);

  /** @ignore expiry dates for each of the caches */
  private _cacheExpiry = new Map<TokenType, DateTime>();

  /** @ignore **/
  constructor(private _deviceService: DeviceService, private _translate: TranslocoService) {}

  /**
   * The datasource function to be passed to the NLP input component
   *
   * @param field The field for which we want to retrieve data (e.g. Operational State, IP Address,
   *etc...)
   * @param partialValue A partial value used to filter the results
   * @param fieldValue Specifies a value for the field for which we want to retrieve data (e.g. for
   *Tags, field = TagField and the fieldValue = 0_Content will indicate that we want to retrieve
   *data for the tag named 0_Content)
   **
   */
  datasourceFn(tokenType: TokenType, partialValue: string, fieldValue = null): Observable<ITokenSuggestion[]> {
    partialValue = (partialValue || '').toLowerCase();

    // if we have a field value then we need to use it for autocomplete
    // otherwise use the next possible field tokenSuggestions
    switch (tokenType) {
      case Hostname:
      case IpAddress: {
        const searchFieldName = tokenType === Hostname ? 'HOSTNAME' : 'IP_ADDRESS';
        // at least one char required for the suggestion endpoint
        return iif(
          () => partialValue.length > 0,
          this._deviceService.getDeviceInterfaceSuggestion(searchFieldName, partialValue, 100).pipe(
            map((keyList: any) => keyList.keys || []),
            map((data: string[]) => data.map((d) => this._createTokenSuggestion(d, d))),
            catchError(() => of([]))
          ),
          of([])
        );
        break;
      }
      case TypeBrand:
      case TypeModel:
      case TypeSeries: {
        return this._deviceTypeDatasource(tokenType, partialValue);
        break;
      }
      case TagField: {
        if (!fieldValue) {
          if (partialValue.length > 1) {
            return this.tagsDatasource(partialValue);
          }
        }
        break;
      }
    }
    return of([]);
  }

  /** Inits parser with nlp field/enum i18n display values **/
  initParserI18n(parser: NlpExpressionParser): void {
    parser.clear();

    const nlpKeys = [];
    const deviceKeys = [];

    // seperate keys based on scope, it's not ideal, but transloco is not perfect when using
    // selectTranslate
    parser.getTokensI18nKeys().forEach((item) => {
      if (item.startsWith('NLP')) {
        nlpKeys.push(item);
      }
      if (item.startsWith('DEVICE')) {
        deviceKeys.push(item);
      }
    });

    // convert each list native filter to the display expression in the user language
    // cannot use translate.translate as key might not be loaded yet at this stage
    zip(this._translate.selectTranslate(nlpKeys), this._translate.selectTranslate(deviceKeys, {}, 'device-fields'))
      .pipe(take(1))
      .subscribe(([nlpLabels, devicesLabels]) => {
        nlpKeys.forEach((key, index) => parser.addTokenI18nValue(key, nlpLabels[index]));
        deviceKeys.forEach((key, index) => parser.addTokenI18nValue(key, devicesLabels[index]));
        // add enum i18n display labels
        if (parser.tokens.includes(NlpOperationalState)) {
          enumToMap<string>(OperationalState).forEach((value, key) =>
            parser.addI18nEnumValue(NlpOperationalState, StringValue, StringValue, value, this._translate.translate('deviceFields.DEVICE.ENUM.OPERATIONAL_STATE.' + getI18nFormattedValue(key)))
          );
        }
        if (parser.tokens.includes(MvHealthStatus)) {
          enumToMap<number>(HealthStatusCode).forEach((value, key) =>
            parser.addI18nEnumValue(MvHealthStatus, NumericValue, StringValue, `${value}`, this._translate.translate('deviceFields.DEVICE.ENUM.HEALTH_STATUS.' + getI18nFormattedValue(key)))
          );
        }
        // enum for Enclosure type
        if (parser.tokens.includes(EncType)) {
          Object.entries(EnclosureType).forEach(([key, value]) => {
            parser.addI18nEnumValue(EncType, StringValue, StringValue, value, this._translate.translate('deviceFields.DEVICE.ENUM.ENCLOSURE_TYPE.' + getI18nFormattedValue(key)));
          });
        }
        // enum for Enclosure mode
        if (parser.tokens.includes(EncMode)) {
          Object.entries(EnclosureMode).forEach(([key, value]) => {
            parser.addI18nEnumValue(EncMode, StringValue, StringValue, value, this._translate.translate('deviceFields.DEVICE.ENUM.ENCLOSURE_MODE.' + getI18nFormattedValue(key)));
          });
        }
        enumToMap<number>(ServiceStatusCode).forEach((value, key) => {
          [MvFtpSvc, MvHttpSvc, MvOmnicastSvc, MvPlayerSvc, MvPlayerSt, MvPlayerCs]
            .filter((t) => parser.tokens.includes(t))
            .forEach((token) =>
              parser.addI18nEnumValue(token, NumericValue, StringValue, `${value}`, this._translate.translate('deviceFields.DEVICE.ENUM.SERVICE_STATUS.' + getI18nFormattedValue(key)))
            );
        });
      });
  }

  /**
   * @ignore Creates a list of suggested options from the device types endpoint.
   * Caches the results so its only fetch the first time
   */
  private _deviceTypeDatasource(tokenType: TokenType, searchValue: string): Observable<ITokenSuggestion[]> {
    // device types are unlinkely to change so just cache them once they are fetched
    let values$: Observable<string[]>;
    // since the same endpoint is used for TypeBrand, TypeModel and TypeSeries, just use TypeBrand as cache key
    if (!this._isCacheExpired(tokenType)) {
      values$ = of(this._cache.get(tokenType));
    } else {
      values$ = this._deviceService.getDeviceTypes().pipe(
        tap((deviceTypes) => {
          // build our cache
          const brandArray = [];
          const modelArray = [];
          const seriesArray = [];
          deviceTypes.forEach((dt) => {
            if (dt.brand && !brandArray.includes(dt.brand)) {
              brandArray.push(dt.brand);
            }
            if (dt.model && !modelArray.includes(dt.model)) {
              modelArray.push(dt.model);
            }
            if (dt.series && !seriesArray.includes(dt.series)) {
              seriesArray.push(dt.series);
            }
          });
          // save into cache
          this._updateCache(TypeBrand, brandArray.sort());
          this._updateCache(TypeModel, modelArray.sort());
          this._updateCache(TypeSeries, seriesArray.sort());
        }),
        map(() => this._cache.get(tokenType)),
        catchError(() => of([]))
      );
    }

    // no way to filter in the api call so let's filter the results
    return values$.pipe(
      map((values) => values.filter((value) => value.toLowerCase().indexOf(searchValue) > -1 && searchValue.length < value.length)),
      map((values) => values.map((value) => this._createTokenSuggestion(value, value)))
    );
  }

  /** @ignore Creates a list of suggested options from the tags endpoint. **/
  tagsDatasource(partialValue: string): Observable<ITokenSuggestion[]> {
    let values$: Observable<string[]>;
    if (!this._isCacheExpired(TagField)) {
      values$ = of(this._cache.get(TagField));
    } else {
      values$ = this._deviceService.getTags('true').pipe(
        map((tags) => tags.map((kv) => kv.key)),
        tap((tags) => {
          // save into cache
          this._updateCache(TagField, tags);
        }),
        map(() => this._cache.get(TagField)),
        catchError(() => of([]))
      );
    }
    return values$.pipe(
      map((tags) => tags.filter((tag) => tag.toLowerCase().indexOf(partialValue) > -1 && partialValue.length < tag.length)),
      map((tags) => this._sortByStartWithFirst(tags, partialValue)),
      map((tags) => tags.map((tag) => this._createTokenSuggestion(tag, tag, this._translate.translate(`NLP.CATEGORY.TAGS`), 'fa:tag')))
    );
  }

  /** @ignore **/
  private _createTokenSuggestion(value: string, label: string, group = null, icon?: string): ITokenSuggestion {
    return {
      value,
      label,
      showSuggestion: true,
      isNextToken: undefined,
      icon,
      group,
    };
  }

  /** @ignore **/
  private _isCacheExpired(tokenType: TokenType) {
    // a cache is considered expired if:
    // - it has no cache value yet
    // - a cache value exits but its ttl is expired
    if (!this._cache.has(tokenType)) {
      return true;
    }
    const expiryDate = this._cacheExpiry.get(tokenType);
    const hasExpired = expiryDate && expiryDate < DateTime.now();
    return hasExpired;
  }

  /** @ignore **/
  private _updateCache(tokenType: TokenType, data: any) {
    this._cache.set(tokenType, data);
    // set the expiry date if there is a TTL
    const ttl = this._cacheTTL.get(tokenType);
    if (ttl) {
      this._cacheExpiry.set(tokenType, DateTime.now().plus({ millisecond: ttl }));
    }
  }

  /**
   * @ignore
   * sorts the array of values alphabetically with:
   * - values that starts with the partial value first
   * - values that contain with the partial value next
   * *
   */
  private _sortByStartWithFirst(values: string[], partialValue: string): string[] {
    return values.sort((a, b) => {
      if (a.toLowerCase().startsWith(partialValue.toLowerCase()) && b.toLowerCase().startsWith(partialValue.toLowerCase())) {
        return a < b ? -1 : 1;
      }
      if (a.toLowerCase().startsWith(partialValue.toLowerCase())) {
        return -1;
      }
      if (b.toLowerCase().startsWith(partialValue.toLowerCase())) {
        return 1;
      }
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }
}
