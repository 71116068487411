import { IModalComponent, IModalConfig, ModalRef, MODAL_CONFIG } from '@activia/ngx-components';
import { EngineTagLevel, IEngineTagKey } from '@amp/tag-operation';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { IModalPickerComponent } from '../../../models/modal-picker-component.interface';

@Component({
  selector: 'amp-orgpath-add-tag-modal',
  templateUrl: './orgpath-add-tag-modal.component.html',
  styleUrls: ['./orgpath-add-tag-modal.component.scss'],
})
export class OrgpathAddTagModalComponent implements IModalComponent, IModalPickerComponent<IEngineTagKey> {
  @Output() saved = new EventEmitter<IEngineTagKey>();
  @Output() cancel = new EventEmitter();

  tagLevel: EngineTagLevel;

  constructor(
    private _dialogRef: ModalRef<OrgpathAddTagModalComponent>,
    @Inject(MODAL_CONFIG)
    public dialogConfig: IModalConfig<void>
  ) {}

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  onSave(newTag: IEngineTagKey) {
    this.saved.emit(newTag);
    this.onClose();
  }
}
