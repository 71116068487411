import { DisplayType, IColumnDefinition } from '@amp/column-picker';

import { DeviceColumnType } from './device-column-type';
import { DeviceProperty } from './device-properties.enum';
import { MonitoredValue } from './monitored-value.enum';
import { DevicePropertiesStatus } from './device-properties-status.enum';
import { ContentStatus } from './content-status.enum';

/**  @ignore */
const getDevicePropertyInfo = (id: string) => ({
  id,
  name: `monitoringScope.MONITORING.COLUMNS.PROPERTY.${id}.NAME_30`,
  description: `monitoringScope.MONITORING.COLUMNS.PROPERTY.${id}.DESCRIPTION_200`,
  type: DeviceColumnType.DeviceProperty,
});

/**  @ignore */
const getMonitoredValueInfo = (id: string) => ({
  id,
  name: `monitoringScope.MONITORING.COLUMNS.MONITORED_VALUE.${id}.NAME_30`,
  description: `monitoringScope.MONITORING.COLUMNS.MONITORED_VALUE.${id}.DESCRIPTION_200`,
  type: DeviceColumnType.MonitoredValue,
});

/**
 * This contains metadata about all the device properties columns:
 * - id
 * - i18n keys for name + description
 * - display types and default display types
 */
export const DEVICE_PROPERTY_COLUMNS: IColumnDefinition[] = [
  { ...getDevicePropertyInfo(DeviceProperty.Id), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '1000' },
  {
    ...getDevicePropertyInfo(DeviceProperty.Name),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
  },
  { ...getDevicePropertyInfo(DeviceProperty.IpAddress), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '10.0.0.1' },
  { ...getDevicePropertyInfo(DeviceProperty.Hostname), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'example.com' },
  { ...getDevicePropertyInfo(DeviceProperty.HostnameIp), defaultDisplayType: DisplayType.HostnameIp, displayTypes: [DisplayType.HostnameIp, DisplayType.TwoLines], sample: { line1: 'example.com', line2: '10.0.0.1' } },
  { ...getDevicePropertyInfo(DeviceProperty.MacAddress), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '00:0a:00:0d:00:00' },
  { ...getDevicePropertyInfo(DeviceProperty.OperationalState), defaultDisplayType: DisplayType.OperationalState, displayTypes: [DisplayType.OperationalState], sample: 'Running' },
  { ...getDevicePropertyInfo(DeviceProperty.ResolveHostname), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: true },
  {
    ...getDevicePropertyInfo(DeviceProperty.Serial),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
  },
  { ...getDevicePropertyInfo(DeviceProperty.Brand), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'Stratacache' },
  { ...getDevicePropertyInfo(DeviceProperty.Model), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'S-200-Linux' },
  { ...getDevicePropertyInfo(DeviceProperty.Series), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'Spectra 200' },
  { ...getDevicePropertyInfo(DeviceProperty.Type), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'Player' },
  { ...getDevicePropertyInfo(DeviceProperty.Longitude), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: -12.3456789 },
  { ...getDevicePropertyInfo(DeviceProperty.Latitude), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 12.3456789 },
  { ...getDevicePropertyInfo(DeviceProperty.Street), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '5th Avenue' },
  { ...getDevicePropertyInfo(DeviceProperty.City), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'New York' },
  { ...getDevicePropertyInfo(DeviceProperty.State), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'NY' },
  { ...getDevicePropertyInfo(DeviceProperty.Country), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'United States of America' },
  { ...getDevicePropertyInfo(DeviceProperty.Continent), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'North America' },
  { ...getDevicePropertyInfo(DeviceProperty.PostalCode), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '10153' },
];

/**
 * This contains metadata about all the device monitored values columns:
 * - id
 * - i18n keys for name + description
 * - display types and default display types
 */
export const MONITORED_VALUE_COLUMNS: IColumnDefinition[] = [
  // For 5.1
  { ...getMonitoredValueInfo(MonitoredValue.AgentVersion), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'agent-4.2.0.23-g6fd6ebd' },
  { ...getMonitoredValueInfo(MonitoredValue.CcpRespTime), defaultDisplayType: DisplayType.Duration, displayTypes: [DisplayType.Duration, DisplayType.Raw], sample: 5000 },
  { ...getMonitoredValueInfo(MonitoredValue.ContentState), defaultDisplayType: DisplayType.Status, displayTypes: [DisplayType.Raw, DisplayType.Status], sample: 1 },
  { ...getMonitoredValueInfo(MonitoredValue.ContentStateMessage), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'Content xxx is missing' },
  {
    ...getMonitoredValueInfo(MonitoredValue.CpuPercent),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Raw, DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    sample: 25,
  },
  { ...getMonitoredValueInfo(MonitoredValue.Firmware), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'Android_V1.0_20190510' },
  { ...getMonitoredValueInfo(MonitoredValue.FtpStatus), defaultDisplayType: DisplayType.ServiceStatus, displayTypes: [DisplayType.Raw, DisplayType.ServiceStatus], sample: true },
  { ...getMonitoredValueInfo(MonitoredValue.HttpService), defaultDisplayType: DisplayType.ServiceStatus, displayTypes: [DisplayType.Raw, DisplayType.ServiceStatus], sample: true },
  { ...getMonitoredValueInfo(MonitoredValue.OmnicastStatus), defaultDisplayType: DisplayType.ServiceStatus, displayTypes: [DisplayType.Raw, DisplayType.ServiceStatus], sample: true },
  { ...getMonitoredValueInfo(MonitoredValue.OmnicastVersion), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '4.6.3' },
  { ...getMonitoredValueInfo(MonitoredValue.PlayerState), defaultDisplayType: DisplayType.Status, displayTypes: [DisplayType.Raw, DisplayType.Status], sample: 1 },
  { ...getMonitoredValueInfo(MonitoredValue.PlayerStateMessage), defaultDisplayType: DisplayType.RawWithTooltip, displayTypes: [DisplayType.RawWithTooltip], sample: 'power connection failed' },
  { ...getMonitoredValueInfo(MonitoredValue.PlayerVersion), defaultDisplayType: DisplayType.PlayerVersion, displayTypes: [DisplayType.PlayerVersion], sample: { versionCode: '4.3.1' } },
  {
    ...getMonitoredValueInfo(MonitoredValue.Playlist),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
  },
  { ...getMonitoredValueInfo(MonitoredValue.PlaylistStartTime), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '12:00:00 PM' },
  { ...getMonitoredValueInfo(MonitoredValue.PlaylistStopTime), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: '2:00:00 PM' },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyByteHitRatio), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyClients), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyCpu), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyHitRatio), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyHitResponse), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyHttpKbClientOut), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyHttpKbServerIn), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyMemory), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyMissResponse), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyTransactions), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ProxyUptime), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 25 },
  { ...getMonitoredValueInfo(MonitoredValue.ServicePlayer), defaultDisplayType: DisplayType.ServiceStatus, displayTypes: [DisplayType.Raw, DisplayType.ServiceStatus], sample: true },
  { ...getMonitoredValueInfo(MonitoredValue.ServicePlayerMessage), defaultDisplayType: DisplayType.Raw, displayTypes: [DisplayType.Raw], sample: 'Playback engine requires attention' },
  {
    ...getMonitoredValueInfo(MonitoredValue.Subplaylists),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.SystemDisk),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Raw, DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    sample: 25,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.SystemMemory),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Raw, DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    sample: 25,
  },
  { ...getMonitoredValueInfo(MonitoredValue.Uptime), defaultDisplayType: DisplayType.Duration, displayTypes: [DisplayType.Raw, DisplayType.Duration], sample: 18000 },
  { ...getMonitoredValueInfo(MonitoredValue.HealthStatus), defaultDisplayType: DisplayType.HealthStatus, displayTypes: [DisplayType.Raw, DisplayType.HealthStatus], sample: 0 },
  { ...getMonitoredValueInfo(MonitoredValue.HealthErrorIds), defaultDisplayType: DisplayType.HealthErrorIds, displayTypes: [DisplayType.Raw, DisplayType.HealthErrorIds], sample: '300, 400' },
  {
    ...getMonitoredValueInfo(MonitoredValue.LastAttempt),
    defaultDisplayType: DisplayType.DateTime,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.LastUpdate),
    defaultDisplayType: DisplayType.DateTime,
    displayTypes: [DisplayType.DateTime, DisplayType.TimeAgo],
    sample: '2020-06-18T20:50:14.165Z',
  },
  // For 5.2
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfPosVersion),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'posprocessor-clientname_2.0.0.4-g17a0d92',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfPosLibsVersion),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: '2.0.0.4',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.PosState),
    defaultDisplayType: DisplayType.ServiceStatus,
    displayTypes: [DisplayType.Raw, DisplayType.ServiceStatus],
    sample: 1,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.PosLastUpdate),
    defaultDisplayType: DisplayType.DateTime,
    displayTypes: [DisplayType.Raw, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.PosLastModified),
    defaultDisplayType: DisplayType.DateTime,
    displayTypes: [DisplayType.Raw, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.PosMessage),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'products:No Changes',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfCodVersion),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'cod-clientname_1.0.0.4-g870a012',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.CodState),
    defaultDisplayType: DisplayType.ServiceStatus,
    displayTypes: [DisplayType.Raw, DisplayType.ServiceStatus],
    sample: 1,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.CodLastUpdate),
    defaultDisplayType: DisplayType.DateTime,
    displayTypes: [DisplayType.Raw, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.CodMessage),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'Error',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ContentStatus),
    defaultDisplayType: DisplayType.ContentStatus,
    displayTypes: [DisplayType.Raw, DisplayType.ContentStatus],
    sample: ContentStatus.OK,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ContentStatusErrorIds),
    defaultDisplayType: DisplayType.ContentStatusErrorIds,
    displayTypes: [DisplayType.Raw, DisplayType.ContentStatusErrorIds],
    sample: '100',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.LastContentUpToDate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.TimeAgo, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.LastContentUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.TimeAgo, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.LastContentFailedUpdate),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.TimeAgo, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.TagsLastModificationTime),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.TimeAgo, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.DynamicTagsLastModificationTime),
    defaultDisplayType: DisplayType.TimeAgo,
    displayTypes: [DisplayType.TimeAgo, DisplayType.DateTime],
    sample: '2020-06-18T20:50:14.165Z',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.DevicePropertiesStatus),
    defaultDisplayType: DisplayType.DevicePropertiesStatus,
    displayTypes: [DisplayType.Raw, DisplayType.DevicePropertiesStatus],
    sample: DevicePropertiesStatus.OK,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfEnclosureFans),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw],
    sample: 10,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfEnclosureFanstype),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'Fan type',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfEnclosureType),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'Single, Double',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.ConfEnclosureVersion),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: '1.4.7D5-PRO1.0',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosureFantime),
    defaultDisplayType: DisplayType.Duration,
    displayTypes: [DisplayType.Raw, DisplayType.Duration],
    sample: 5000,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosureHumid0),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Raw, DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    sample: 25,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosureHumid1),
    defaultDisplayType: DisplayType.PercentChart,
    displayTypes: [DisplayType.Raw, DisplayType.Percent, DisplayType.PercentChart, DisplayType.PercentPieChart],
    sample: 25,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosureMode),
    defaultDisplayType: DisplayType.Raw,
    displayTypes: [DisplayType.Raw],
    sample: 'Cool, Heat',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosurePower0),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: 1.5,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosurePower1),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: 1.5,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosurePressure),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: 22.5,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosurePwm0),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: 255,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.EnclosurePwm1),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: 255,
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.TemperatureEnclosureMin),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: '25.9',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.TemperatureEnclosureMax),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: '25.9',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.TemperatureEnclosureAim),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: '25.9',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.TemperatureEnclosureEx),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: '25.9',
  },
  {
    ...getMonitoredValueInfo(MonitoredValue.TemperatureEnclosureIn),
    defaultDisplayType: DisplayType.Numeric,
    displayTypes: [DisplayType.Raw, DisplayType.Numeric],
    sample: '25.9',
  },
];
