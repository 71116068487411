<avn-loader avnTheme="primary" [isLoading]="loading"></avn-loader>
<amp-column-picker
  [dataSource]="columns"
  [selectedColumns]="selectedColumns"
  [i18nLabels]="{
    treeviewEmptyListMessage: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.NO_RESULT_FOUND_20' | transloco,
    treeviewSearchPlaceholder: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.SEARCH_20' | transloco,
    listSelectedColumnsTitle: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.SELECTED_COLUMNS_50' | transloco,
    listNoColumnsSelectedMessage: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.NO_ITEM_SELECTED_50' | transloco,
    renameColumnTooltip: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.RENAME_COLUMN_TOOLTIP_30' | transloco,
    columnNameInputPlaceholder: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.COLUMN_NAME_INPUT_PLACEHOLDER_25' | transloco,
    renameColumnSaveButtonLabel: 'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.RENAME_COLUMN_SAVE_BUTTON_10' | transloco
  }"
  [displayTypeTemplate]="displayTypeTemplate"
  [extraColumnConfigTemplate]="extraColumnConfigTemplate"
  (valueChanged)="updatedSelectedColumns($event)"
  (columnNameChanged)="columnNameChanged.emit($event)"
></amp-column-picker>
<ng-template #displayTypeTemplate let-item="item" let-displayType="displayType">
  <amp-site-field-templates
    [field]="item.columnDefinition.id"
    [value]="item.columnDefinition.sample"
    [displayType]="displayType"
    [optimisticViewEnabled]="item.extra?.optimisticViewEnabled"
  ></amp-site-field-templates>
</ng-template>

<ng-template #extraColumnConfigTemplate let-item="item">
  <avn-checkbox
    [label]="'siteMonitoringSharedScope.SITE_MONITORING.COLUMNS_PICKER.SHOW_OPTIMISTIC_VIEW_30' | transloco"
    [checked]="item.extra.optimisticViewEnabled"
    (change)="onOptimisticViewChange($event.target.checked, item)"
    [avnTooltip]="'siteMonitoringSharedScope.SITE_MONITORING.OPTIMISTIC_VIEW.' + item.columnDefinition.id + '.DESCRIPTION_250' | transloco"
    [tooltipPlacement]="'left'"
  ></avn-checkbox>
</ng-template>
