import { PERMISSIONS } from '@amp/auth';
import { HttpErrorResponse } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';

export interface IDownloadLogsStatus {
  deviceId: number;
  downloading: boolean;
}

export interface IDownloadLogsJob {
  subject: ReplaySubject<IDownloadLogsStatus>;
}

export interface IDownloadLogsJobMessage {
  title: string;
  message: string;
  messageParams?: any;
  error?: HttpErrorResponse;
}

export const DOWNLOAD_LOGS = {
  name: 'DOWNLOAD_LOGS',
  label: 'deviceFields.DEVICE.DOWNLOAD_LOGS.TITLE_50',
  icon: 'av:library_books',
  relativePipUrl: 'anylog.php?name=activia',
  permission: <PERMISSIONS>'GET_MONITORING_VIEW',
};
